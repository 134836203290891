import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/functions';
import firestore from "firebase/firestore";
import * as firebase from 'firebase';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: "1:641267299443:web:db044f8a91df4a8030a606",
  measurementId: "G-1NZ8N810YG"
};

class Firebase {
  auth: app.auth.Auth;
  db: any;
  firestore: any;
  serverValue: any;
  functions: firebase.functions.Functions;

  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.db = app.database();
    this.firestore = firebase.firestore();
    this.serverValue = app.database.ServerValue;
    this.functions = app.functions();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email: string, password: string) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email: string, password: string) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => {
    this.auth.signOut()
  };

  doPasswordReset = (email: string) => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (password: string) => {
    if(this.auth.currentUser) {
      this.auth.currentUser.updatePassword(password);
    }
  }

  user = (uid: string) => this.db.ref(`users/${uid}`);
  promoCode = (code: string) => this.db.ref(`promoCodes/${code}`);

  users = () => this.db.ref('users');
  badges = () => this.db.ref('badge');
  promoCodes = () => this.db.ref('promoCodes');

  
}

export default Firebase;