import React, { useState } from 'react';
import * as ROUTES from '../../constants/routes';
import { AuthUserContext, withAuthorization } from '../Session';
import { Link, withRouter } from 'react-router-dom';
import {
    Button,
    Form,
    Grid,
    Header,
    Message,
    Segment,
    Divider,
    List,
    Image,
    Accordion,
    Icon,
    InputOnChangeData
} from 'semantic-ui-react';

function GiftFaqPage(props) {
    
    let [currentIndex, setCurrentIndex] = useState(0);
    
    return (
        <Accordion styled padded>
        
        <Accordion.Title
        active={currentIndex === 0}
        index={0}
        onClick={() => setCurrentIndex(0)}
        >
        <Header as="h3" textAlign="center">FAQ</Header>
        <Icon name='dropdown' />
        How does the gift membership work?
        </Accordion.Title>
        <Accordion.Content active={currentIndex === 0}>
        <p>
        After you purchase a gift membership, we’ll send you an email containing the digital gift card with the gift code details for the gift recipient. You can either forward that email or send that information to them directly. Once they have the information, the gift recipient will simply need to create an account and apply the gift code on our website! Once they activate their account with the promo code, their subscription will begin!
        </p>
        </Accordion.Content>
        
        <Accordion.Title
        active={currentIndex === 1}
        index={1}
        onClick={() => setCurrentIndex(1)}
        >
        <Icon name='dropdown' />
        What does the gift recipient need to do?
        </Accordion.Title>
        <Accordion.Content active={currentIndex === 1}>
        <p>
        After your purchase, we’ll send you all the necessary information for the gift recipient to get started. In short, they’ll need to create an account and apply the promo code. Once they do, they’ll just need to login into the app and they’ll be set.
        </p>
        </Accordion.Content>
        
        <Accordion.Title
        active={currentIndex === 2}
        index={2}
        onClick={() => setCurrentIndex(2)}
        >
        <Icon name='dropdown' />
        What happens after the gift membership period ends?
        </Accordion.Title>
        <Accordion.Content active={currentIndex === 2}>
        <p>
        After the gift membership is completed, the recipient will no longer have access to Tappity. Your credit card will not be charged. They will have the opportunity to sign-up for a subscription themselves.
        </p>
        </Accordion.Content>
        <Accordion.Title
        active={currentIndex === 3}
        index={3}
        onClick={() => setCurrentIndex(3)}
        >
        <Icon name='dropdown' />
        Can I get a refund on the gift membership purchase?
        </Accordion.Title>
        <Accordion.Content active={currentIndex === 3}>
        <p>
        Our gift membership purchases are final. However, if you have any concerns, please contact us at hey@tappityapp.com
        </p>
        </Accordion.Content>
        
        
        <Accordion.Title
        active={currentIndex === 4}
        index={4}
        onClick={() => setCurrentIndex(4)}
        >
        <Icon name='dropdown' />
        How many gift membership can I buy?
        </Accordion.Title>
        <Accordion.Content active={currentIndex === 4}>
        <p>
        As many as you’d like! 
        </p>
        </Accordion.Content>
        
        <Accordion.Title
        active={currentIndex === 5}
        index={5}
        onClick={() => setCurrentIndex(5)}
        >
        <Icon name='dropdown' />
        Can the gift membership be used for multiple kids in one family?
        </Accordion.Title>
        <Accordion.Content active={currentIndex === 5}>
        <p>
        Yes! The gift membership gives one account (i.e. email address) unlimited access to Tappity for 6 months. If more than one child would like to use Tappity, then the gift recipient will simply need to add profiles to that account for each child! 
        </p>
        </Accordion.Content>
        
        <Accordion.Title
        active={currentIndex === 6}
        index={6}
        onClick={() => setCurrentIndex(6)}
        >
        <Icon name='dropdown' />
        What devices does Tappity support?
        </Accordion.Title>
        <Accordion.Content active={currentIndex === 6}>
        <p>
        Tappity is currently only supported on iOS devices with iOS 13.2+. However, the desktop version will be available soon!
        </p>
        </Accordion.Content>
        
        <Accordion.Title
        active={currentIndex === 7}
        index={7}
        onClick={() => setCurrentIndex(7)}
        >
        <Icon name='dropdown' />
        I have other questions. How can I contact you?
        </Accordion.Title>
        <Accordion.Content active={currentIndex === 7}>
        <p>
        Please contact us at hey@tappityapp.com
        </p>
        </Accordion.Content>
        </Accordion>
        
        );
    }
    
    
    export default GiftFaqPage;