import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Navigation from '../Navigation';
import LandingPage from '../Landing';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import EnterPromoCodePage from '../EnterPromoCode';
import UserInfo from '../UserInfo';
import HomePage from '../Home';
import PlansPage from '../Plans';
import ReferPlansPage from '../ReferPlans';
import AccountPage from '../Account';
import AdminPage from '../Admin';
import CheckoutPage from '../Checkout';
import LivePage from '../Live';
import LifetimeDiscountPage from '../LifetimeDeal';
import PaymentSuccessPage from '../PaymentSuccess';
import PaymentSuccessWithVideoPage from '../PaymentSuccessWithVideo';
import ReferralTrackerPage from '../ReferralTracker';
import AdminLivePage from '../TappityLive';
import GiftPage from '../Gift';
import GiftPurchaseSuccessPage from '../GiftPurchaseSuccess';
import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
import { Container, Divider } from 'semantic-ui-react';
import {Elements} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Footer from '../Footer'
import ReactGA from 'react-ga';

const stripePromise = loadStripe("pk_live_ijImBoeMBdY1M8t6OQ2NRIA2");

ReactGA.initialize('UA-151432962-1');


const App = () => (
  <Elements stripe={stripePromise}>
  <Router>
      
      <Navigation />
      <Divider hidden></Divider>
      <Container>
      <Route exact path={ROUTES.PLANS_LANDING} component={ReferPlansPage} />
        <Route path={ROUTES.ACCOUNT} component={AccountPage} />
        <Route exact path={ROUTES.LANDING} component={LandingPage} />
        <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
        <Route path={ROUTES.SIGN_IN} component={SignInPage} />
        <Route path={ROUTES.ENTER_PROMO_CODE} component={EnterPromoCodePage} />
        <Route path={ROUTES.USER_INFO} component={UserInfo} />
        <Route path={ROUTES.PLANS} component={PlansPage} />
        <Route path={ROUTES.REFERRAL_TRACKER} component={ReferralTrackerPage} />
        <Route path={ROUTES.REFER_PLANS} component={ReferPlansPage} />
        <Route path={ROUTES.CHECKOUT} component={CheckoutPage} />
        <Route path={ROUTES.LIFETIME_DISCOUNT} component={LifetimeDiscountPage} />
        <Route path={ROUTES.ADMIN_LIVE} component={AdminLivePage} />
        <Route path={ROUTES.LIVE} component={LivePage} />
        <Route path={ROUTES.GIFT} component={GiftPage} />
        <Route path={ROUTES.GIFTSUCCESS} component={GiftPurchaseSuccessPage} />
        <Route path={ROUTES.PAYMENT_SUCCESS} component={PaymentSuccessPage} />
        <Route path={ROUTES.PAYMENT_SUCCESS_WITH_VIDEO} component={PaymentSuccessWithVideoPage} />
        
        <Route
          path={ROUTES.PASSWORD_FORGET}
          component={PasswordForgetPage}
        />
        <Route path={ROUTES.HOME} component={HomePage} />
        
        <Route path={ROUTES.ADMIN} component={AdminPage} />
      </Container>

      <Footer />
  </Router>
  </Elements>
);

export default withAuthentication(App);