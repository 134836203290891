import React, { useState } from 'react';
import * as ROUTES from '../../constants/routes';
import { AuthUserContext, withAuthorization } from '../Session';
import { Link, withRouter } from 'react-router-dom';
import {
    Button,
    Form,
    Grid,
    Header,
    Message,
    Segment,
    Divider,
    List,
    Image,
    Accordion,
    Icon,
    InputOnChangeData
} from 'semantic-ui-react';

function ReferralFaqBoxPage(props) {
    
    let [currentIndex, setCurrentIndex] = useState(-1);
    
    return (
        <Accordion styled padded>
        
        <Accordion.Title
        active={currentIndex === 0}
        index={0}
        onClick={() => setCurrentIndex(0)}
        >
        <Header as="h3" textAlign="center">FAQ</Header>
        <Icon name='dropdown' />
        How will I know when my referral subscribes?
        </Accordion.Title>
        <Accordion.Content active={currentIndex === 0}>
        <p>
        We will update your referral tracker here on this website.
        </p>
        </Accordion.Content>
        
       
        
        <Accordion.Title
        active={currentIndex === 2}
        index={2}
        onClick={() => setCurrentIndex(2)}
        >
        <Icon name='dropdown' />
        My friend subscribed using my link but the tracker hasn't been credited. What should I do?
        </Accordion.Title>
        <Accordion.Content active={currentIndex === 2}>
        <p>
        If you have questions about credits, please contact us at hey@tappityapp.com
        </p>
            </Accordion.Content>
            
        <Accordion.Title
        active={currentIndex === 3}
        index={3}
        onClick={() => setCurrentIndex(3)}
            >
                
        <Icon name='dropdown' />
        I referred someone but they didn't use my link. Can I still claim credit?
        </Accordion.Title>
        <Accordion.Content active={currentIndex === 3}>
        <p>
        Unfortunately, your referral must use the special URL in your account to subscribe.
        </p>
        </Accordion.Content>
        
        
        <Accordion.Title
        active={currentIndex === 4}
        index={4}
        onClick={() => setCurrentIndex(4)}
        >
        <Icon name='dropdown' />
        How can I share this link to others?
        </Accordion.Title>
        <Accordion.Content active={currentIndex === 4}>
        <p>
        Any way you want! Email, text, and social media are the most popular ways. 
        
        </p>
            </Accordion.Content>
           
        <Accordion.Title
        active={currentIndex === 5}
        index={5}
        onClick={() => setCurrentIndex(5)}
        >
        <Icon name='dropdown' />
        Who can I refer to earn rewards?
        </Accordion.Title>
        <Accordion.Content active={currentIndex === 4}>
        <p>
        Your referrals must be a new customer of Tappity. In other words, they must not have subscribed to Tappity before.   
        
        </p>
        </Accordion.Content>
        
        <Accordion.Title
        active={currentIndex === 5}
        index={5}
        onClick={() => setCurrentIndex(5)}
        >
        <Icon name='dropdown' />
        When will my child receive their reward?
        </Accordion.Title>
        <Accordion.Content active={currentIndex === 4}>
        <p>
        We will send it to your family within 30 days after you claim the reward.    
        </p>
        </Accordion.Content>
        </Accordion>
        
        );
    }
    
    
    export default ReferralFaqBoxPage;