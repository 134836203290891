import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import * as ROUTES from '../../constants/routes';

//import './CardSectionStyles.css';
import { withFirebase } from '../Firebase';
import { AuthUserContext, withAuthorization } from '../Session';
import {
  Button,
  Form,
  List,
  Grid,
  Header,
  Segment,
  Divider,
  Item,
  Image,
  Icon,
  InputOnChangeData
} from 'semantic-ui-react';
import Cookies from 'universal-cookie';
import ReactPixel from 'react-facebook-pixel';

const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};



const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const strikeStyle = {
  textDecoration: "line-through",
  opacity: "0.6"
  
};

const productStyle = {
  
  
};

const yearNormalDiscountPriceID = "price_1H4uyJATKVrws6NtrTPygCUP";
const yearReferPriceID = "price_1H4uu1ATKVrws6NtHTXEQgFV";
const quarterNormalDiscountPriceID = "plan_Gu5SclinquCi8D";
const lifetimeDiscountPriceID = "price_1H4U7hATKVrws6NthkStS4kW";
const halfYearGiftPriceID = "price_1HoCe2ATKVrws6NtAAse1hfJ";

async function stripePaymentMethodHandler(result, email, uid, props, purchasedError, setPurchasedError) {
  const cookies = new Cookies();
  
  const cookiePriceID = cookies.get('price')
  const cookieRefer = cookies.get('refer')
  
  if (result.error) {
    // Show error in payment form
    return false
  } else {
    
    console.log(result.paymentMethod.id)
    let planID = quarterNormalDiscountPriceID

    if(cookiePriceID != undefined && cookiePriceID != null) {
      planID = cookiePriceID
    }

    var firebaseStripeCreateCustomer = props.firebase.functions.httpsCallable('stripeFunctions-stripeCreateCustomer');

    await firebaseStripeCreateCustomer({email: email,
      payment_method: result.paymentMethod.id,
      planID: planID, //get cookie price and put it here
      uid: uid,
      refer: cookieRefer
    }).then(function (result) {
      console.log(result)
      if(result.data.purchasedThisDiscount != null) {
        console.log("already subbed")
        setPurchasedError(true)
        return false
      }
      else {
        console.log("not already subbed")
        if (cookieRefer == "TAP-TIVBSL") { // comes with video message
          props.history.push(ROUTES.PAYMENT_SUCCESS_WITH_VIDEO);
        }
        else if (cookiePriceID == halfYearGiftPriceID) {
          props.history.push(ROUTES.GIFTSUCCESS);
        }
        else {
          props.history.push(ROUTES.PAYMENT_SUCCESS_WITH_VIDEO);
          //props.history.push(ROUTES.PAYMENT_SUCCESS);
        }
        
        return true
      }
      
    });

    return true
    
    
  }
}


/*async function testRefer(props) {
  var firebaseStripeCreateCustomer = props.firebase.functions.httpsCallable('stripeFunctions-testRefer');
    await firebaseStripeCreateCustomer().then(function (result) {
      console.log(result)
    });
}

async function testEmail(data, props) {
  var firebaseStripeTestEmail = props.firebase.functions.httpsCallable('stripeFunctions-testSendEmail');

    await firebaseStripeTestEmail(data).then(function (result) {
      console.log(result)
      return true
      
    });
}*/

function CheckoutPage(props) {
  
  let [submittedForm, setSubmitted] = useState(false)
  let [purchasedError, setPurchasedError] = useState(false)
  const stripe = useStripe();
  const elements = useElements();
  const cookies = new Cookies();
  
  const cookiePriceID = cookies.get('price')
  const cookieRefer = cookies.get('refer')
  
  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setSubmitted(true)
    var uid = props.firebase?.auth?.currentUser?.uid
    var email = props.firebase?.auth?.currentUser?.email
    
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    
    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        email: email,
      },
    });
    
    let success = await stripePaymentMethodHandler(result, email, uid, props, purchasedError, setPurchasedError);
    console.log("success")
    console.log(success)
    if (success == "subbed") {
      setPurchasedError(true)
      setSubmitted(false)
    }
    if (!success) {
      setSubmitted(false)
    }
  };
  /*var uid = props.firebase?.auth?.currentUser?.uid
    var email = props.firebase?.auth?.currentUser?.email

  testEmail({email: email,
      uid: uid,
    }, props)*/
    
  let priceID = cookiePriceID
  let duration = "(3 Month)"
  let price = "$19.99"
  let fullPrice = "$24.99"
  let percentDiscount = "Save 20%"
  let billingDetails = "Billed every 3 months"
  let referSection = ""
  let productName = "Tappity Subscription"
    let prodImage = "./app-icon.png"
  if (cookiePriceID == null) {
    priceID = quarterNormalDiscountPriceID
    
  } 
  else {
    priceID = cookiePriceID
    if (cookieRefer == "TAP-TIVBSL") {
      referSection =
        <Segment.Inline style={{ marginTop: "20px" }}>
        <List as="p">
        <List.Item>
                <List.Icon color="red" name='gift' style={{paddingTop: "8px"}}/>
                <List.Content>+ A personalized video message from Haley</List.Content>
          </List.Item>
          </List>
      </Segment.Inline>;
    }
  }
  if (priceID === halfYearGiftPriceID) {
    productName = "Tappity Gift Membership"
    duration = "(6 months promo code)"
    price = "$34.99"
    fullPrice = "$49.99"
    percentDiscount = "Save 30%"
    billingDetails = "One time payment (not recurring)"
  }
  else if (priceID === yearNormalDiscountPriceID) {
    duration = "(1 year)"
    price = "$59.99"
    fullPrice = "$74.99"
    percentDiscount = "Save 20%"
    billingDetails = "Billed yearly"
  }
  else if (priceID === lifetimeDiscountPriceID) {
    productName = "Tappity Lifetime Membership"
    duration = " "
    price = "$149.99"
    fullPrice = " "
    percentDiscount = " "
    billingDetails = "One time payment"
  }
  else if (priceID == yearReferPriceID && cookieRefer.toUpperCase().includes("TAP")) {
    duration = "(1 year)"
    price = "$49.99"
    fullPrice = "$74.99"
    percentDiscount = "Save 33%"
    billingDetails = "Billed yearly"
    referSection =
      <Segment.Inline style={{ marginTop: "20px"}}>
        <Header as="h4" textAlign="left">Referral code</Header>
        <p>{cookieRefer}</p>
      </Segment.Inline>;
    if (cookieRefer == "TAP-TIVBSL") {
      referSection =
        <Segment.Inline style={{ marginTop: "20px" }}>
        <List as="p">
        <List.Item>
                <List.Icon color="red" name='gift' style={{paddingTop: "8px"}}/>
                <List.Content>+ A personalized video message from Haley</List.Content>
          </List.Item>
          </List>
      </Segment.Inline>;
    }
  }
  
  //console.log("price")
  //console.log(priceID)
  //console.log(cookieRefer)
  
  let alreadyPurchasedError = <Header as="h4" color="red" textAlign="centered">You've already purchased a subscription for this account. Please contact us at hey@tappityapp.com if you think this is a mistake.</Header>
  let button = <Button type="submit" color="blue" size="large" floated='right' disabled={submittedForm}>Place order<Icon name='right arrow' /></Button>

  if (submittedForm && !purchasedError)  {
    button = <Button color="blue" size="large" floated='right' disabled={submittedForm} loading>Loading</Button>
  }
  if (!purchasedError) {
    alreadyPurchasedError = ""
  }
 
  return (
    
    <Grid centered>
    <Grid.Column mobile={16} tablet={16} computer={16}>{alreadyPurchasedError}</Grid.Column>
    
    <Grid.Column mobile={16} tablet={12} computer={4}>
    <Segment padded>
    <h4>Your item:</h4>
    <Image
            src={prodImage} width="100px"
    />
    <br/>
          <p floated="left"><h4>{productName}<br /> {duration}</h4> {price} <span style={strikeStyle}>{fullPrice}</span> {percentDiscount} <br />{billingDetails}</p>
          {referSection}
    </Segment>
    </Grid.Column>
    <Grid.Column mobile={16} tablet={12} computer={8}>
    
    <Segment padded>
    <Header as="h1" textAlign="left">Checkout</Header>
    
    <form onSubmit={handleSubmit}>
    <Segment.Inline> 
    <Header as="h4" textAlign="left">Email address</Header>
    <p>{props.firebase?.auth?.currentUser.email}</p>
    </Segment.Inline>
    
    
    <Header as="h4" textAlign="left">Enter payment details</Header>
    <CardElement options={CARD_ELEMENT_OPTIONS} />
    <Divider section />
    <Item>
    
    {button}
    </Item>
    <Header  paddedas="h3"  textAlign="left" color="green"><Icon name='lock' /> Stripe</Header>
    
    </form>
    <span>&nbsp;&nbsp;</span>
    </Segment>
    <Segment.Inline inline textAlign="center">
    We use the same 256-bit SSL encryption banks use.
    </Segment.Inline>
    
    
    </Grid.Column>
    
    </Grid>
    );
  }
  
  
  const condition = authUser => !!authUser;
  export default withAuthorization(condition)(CheckoutPage);