import React, { useState, Component } from 'react';
import { AuthUserContext, withAuthorization, withAuthentication } from '../Session';
import {
    Button,
    Form,
    Grid,
    Header,
    Message,
    Segment,
    Divider,
    List,
    Image,
    Accordion,
    Icon,
    InputOnChangeData
} from 'semantic-ui-react';
import Firebase, { withFirebase } from '../Firebase';
import { compose } from 'recompose';
import { Link, withRouter } from 'react-router-dom';
import YouTube from 'react-youtube';
import * as ROUTES from '../../constants/routes';

const pollOptionStyle = {
    backgroundColor: "red",
    fontColor: "white",
    width: "120px",
    height: "60px",
    display: "inline-block",
    margin: "20px 10px 40px 0px",
    textAlign: "center",
    padding: "4px",
};
const pollOptionStyle2 = {
    backgroundColor: "blue",
    fontColor: "white",
    width: "120px",
    height: "60px",
    display: "inline-block",
    margin: "20px 10px 40px 0px",
    textAlign: "center",
    padding: "4px",
};

class LivePage extends Component {
    
    
    
    constructor(props) {
        super(props);  
        
        this.state = {
            signedin: props.firebase?.auth?.currentUser,
            isLive: false,
            isPolling: false,
            membershipRequired: true,
            orangeCount: 0,
            blueCount: 0,
            liveViewers: 0,
            loading: true,
            url: "",
            windowHeight: window.innerHeight,
            windowWidth: window.innerWidth,
            orangeButtonImage: "red-button-up.png",
            blueButtonImage: "blue-button-up.png",
            videoID: "pakLFQ61omE"
        };
        
        const db = this.props.firebase.firestore;
        const dbRealTime = this.props.firebase.db;

        db.collection("tappityLiveEvents").doc("liveEvent1")
            .onSnapshot(function (doc) {
                var val = doc.data()
            console.log("Current data: ", doc.data());
                var video_id = val.streamUrl.split('/')[3];
                console.log("video_id")
            console.log(video_id)
            this.setState({loading: false, videoID: video_id  });
        }.bind(this));

        dbRealTime.ref("polls").on('value', function(snapshot) {
            var val = snapshot.val()
            console.log("Current data: ", val);
            
            this.setState({isPolling: val.visible, orangeCount:  val.orangeCount, blueCount: val.blueCount, loading: false});
        }.bind(this));

        db.collection("seasons").doc("tappity_live")
        .onSnapshot(function(doc) {
            console.log("Current data: ", doc.data());
            this.setState({isLive: doc.data().currentlyLive, membershipRequired: doc.data().membershipRequired, loading: false });
        }.bind(this));

        this.voteOrangeClick = this.voteOrangeClick.bind(this)
        this.voteBlueClick = this.voteBlueClick.bind(this)
        this.orangeDownEvent = this.orangeDownEvent.bind(this)
        this.orangeUpEvent = this.orangeUpEvent.bind(this)

        this.blueDownEvent = this.blueDownEvent.bind(this)
        this.blueUpEvent = this.blueUpEvent.bind(this)
        this.checkIfUser = this.checkIfUser.bind(this)
        //this.checkIfUser()
    }
    
    checkIfUser() {
        let user = this.props.firebase?.auth?.currentUser;

        console.log("CHECK USERS");
        this.props.firebase
        .user(user.uid).on('value', function(snapshot) {
            var val = snapshot.val()
            console.log("User data: ", val);
            let expireDate = val["membership-expire-date"]
            let masterMember = val["master-member"]
            console.log(expireDate)
            console.log(masterMember)
            if (masterMember > 0 || expireDate > 0) {
                
            } else {
                this.props.history.push(ROUTES.REFER_PLANS);
            }
            
        }.bind(this));
    }

    togglePollClick() {
        const db = this.props.firebase.firestore;
        const dbRealTime = this.props.firebase.db;
        if (this.state.isPolling) { // turning off
            dbRealTime.ref("polls").update({
                visible: !this.state.isPolling
            }, function(error) {
                if (error) {
                  // The write failed...
                } else {
                  // Data saved successfully!
                }
              });
            
        }
        else {
            dbRealTime.ref("polls").set({
                blueCount: 0,
                orangeCount: 0,
                visible: !this.state.isPolling
            }, function(error) {
                if (error) {
                  // The write failed...
                } else {
                  // Data saved successfully!
                }
              });
        }
        
    }

    orangeDownEvent() {
        console.log("click")
        this.setState({ orangeButtonImage: "red-button-down.png" });
    }
    orangeUpEvent() {
        console.log("click")
        this.setState({ orangeButtonImage: "red-button-up.png" });
    }

    blueDownEvent() {
        console.log("click")
        this.setState({ blueButtonImage: "blue-button-down.png" });
    }
    blueUpEvent() {
        console.log("click")
        this.setState({ blueButtonImage: "blue-button-up.png" });
    }

    voteOrangeClick() {
        const dbRealTime = this.props.firebase.db;
        var ref = dbRealTime.ref('polls/orangeCount');
        ref.transaction(function(orangeCount) {
        // If node/clicks has never been set, currentRank will be `null`.
        return (orangeCount || 0) + 1;
        });
        
    }

    voteBlueClick() {
        const dbRealTime = this.props.firebase.db;
        var ref = dbRealTime.ref('polls/blueCount');
        ref.transaction(function(blueCount) {
        // If node/clicks has never been set, currentRank will be `null`.
        return (blueCount || 0) + 1;
        });
        
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
       }
      
       componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
       }

    
    handleResize = async (event) => {
        this.setState({ windowWidth: window.innerWidth });
    };

    onChange = (event, { value }) => {
        this.setState({ url: value });
    };
    
    
    onSubmit = async (event) => {
      
        console.log(this.state.url)
        const db = this.props.firebase.firestore;
        const liveRef = db.collection("tappityLiveEvents").doc("liveEvent1").set({
            streamUrl: this.state.url,
        }, { merge: true }).catch(function(error) {
            console.log("Error getting document:", error);
        });
        event.preventDefault();
        
    };
    
    render() {
        let goLiveButtonColor = "blue"
        let goLiveButtonText = "Go live"
        let startPollText = "🤩 Start poll"
        let pollStatus = "🙈 - Poll is NOT showing"

        if (this.state.isLive) {
            goLiveButtonColor = "white"
            goLiveButtonText = "End live event"
        }

        if (this.state.isPolling) {
            startPollText = "🙈 End poll"
            pollStatus = "🤩 - Poll IS showing"
        }
        console.log(this.props.firebase?.auth?.currentUser)
        const opts = {
            height: 1200,
            width: 1600,
            playerVars: {
              // https://developers.google.com/youtube/player_parameters
                autoplay: 1,
                controls: 0,
                disablekb: 1,
                fs: 0,
                modestbranding: 1,
            },
        };
        if (this.state.isLive) {
            return (
                <Grid centered >
                <Grid.Column mobile={16} tablet={6} computer={7}>
                    
                    <Segment padded style={{display:this.state.isPolling ? "block" : "none"}}>
                        <div><div style={{width: 30 + this.state.blueCount/20, background:"blue", color:"white", padding:"5px", borderRadius: "10px", margin:"10px 0px"}}>{this.state.blueCount}</div></div>
                        <div><div style={{ width: 30 + this.state.orangeCount / 20, background: "red", color: "white", padding: "5px", borderRadius: "10px", margin: "10px 0px" }}>{this.state.orangeCount}</div></div>
                        
                    <Button color="clear" size="small" type="submit" onMouseDown={this.orangeDownEvent} onMouseUp={this.orangeUpEvent} onClick={this.voteOrangeClick} style={{background:"RGBA(0,0,0,0)", width:"45%", display:"inline-block"}}>
                        <Image src={this.state.orangeButtonImage} style={{}}/>
                    </Button>
                    <Button color="clear" size="small" type="submit" onMouseDown={this.blueDownEvent} onMouseUp={this.blueUpEvent} onClick={this.voteBlueClick} style={{background:"RGBA(0,0,0,0)", width:"45%", display:"inline-block"}}>
                    <Image src={this.state.blueButtonImage} style={{}}/>
                    </Button>
                    </Segment>
                </Grid.Column>
                <Grid.Column mobile={10} tablet={10} computer={9}>
                    <Segment style={{ padding: "0px", overflow: "hidden", background: "#000000", display: this.state.isLive ? "block" : "none", height: 800 }}>
                        <Image src="tappity-live-active-logo.png" style={{ width: "30%", margin: "5px 35%", display: "inline", position: "absolute" }} />
                        <Segment.Inline style={{ margin: "-200px 0px 0px " + String(-opts.width/3.25) + "px"}}>
                        <YouTube videoId={this.state.videoID} opts={opts} onReady={this._onReady} />
                        </Segment.Inline>
                    </Segment>
                    <Segment padded style={{display:this.state.isLive ? "none" : "block", backgroundImage:"url('not-live-bg.jpg')", backgroundSize:"cover", height: this.state.windowHeight - 200, textAlign: "center"}} inverted>
                        <Image src="tappity-live-logo.png" style={{width: "60%", margin:"0 auto"}}/>
                        <Header as="h1" textAlign="center">Live science shows every Tuesday and Friday at 2 PT</Header>
                    </Segment>
                </Grid.Column>
            </Grid>
            )
        }
        return (
            <Grid centered >
                
                <Grid.Column mobile={10} tablet={10} computer={9}>
                    <Segment padded style={{display:this.state.isLive ? "none" : "block", backgroundImage:"url('not-live-bg.jpg')", backgroundSize:"cover", height: this.state.windowHeight - 200, textAlign: "center"}} inverted>
                        <Image src="tappity-live-logo.png" style={{width: "60%", margin:"0 auto"}}/>
                        <Header as="h1" textAlign="center">Live science shows every Wednesday at 2pm PT and Sunday 8am and 2pm PT</Header>
                        
                        <Header as="h2" textAlign="center" style={{marginTop:"65%"}}>Submit your creations to Haley@tappityapp.com after the show!</Header>
                    </Segment>
                </Grid.Column>
            </Grid>
            )

        if (this.state.loading) {
            return (
                <Header as="h2" textAlign="center">Loading...</Header>
            )
        }
        if (this.state.isLive) {
            return (          
                <Grid centered >
                
                    <Grid.Column mobile={16} tablet={12} computer={12}>
                    <Button color={goLiveButtonColor} size="small" type="submit" onClick={this.toggleLiveClick}>
                    {goLiveButtonText}
                    </Button>
                    
                  </Grid.Column>
                <Grid.Column mobile={16} tablet={12} computer={6}>
                
                        <Segment padded>
                            
                            <Header as="h2" textAlign="left">{pollStatus}</Header>
                            <Segment style={pollOptionStyle}>
                                <Header as="h2" textAlign="center" inverted>{this.state.orangeCount}</Header>
                               
                            </Segment>
                            <Segment style={pollOptionStyle2}>
                                <Header as="h2" textAlign="center" inverted>{this.state.blueCount}</Header>
                               
                            </Segment>
    
                            <Button color="blue" fluid size="Large" type="submit" onClick={this.togglePollClick}>
                    {startPollText}
                    </Button>
                </Segment>
                </Grid.Column>
                    
                <Grid.Column mobile={16} tablet={12} computer={6}>
                    <Segment padded>
                    <Header as="h4" textAlign="center">Update stream</Header>
            <Form size="large" onSubmit={this.onSubmit}>
              <Form.Input fluid label=" " name="url" value={this.state.url} onChange={this.onChange} type="text" placeholder="URL" />
              <Button color="blue" size="small" type="submit"> Submit </Button> 
            </Form>
                </Segment>
                
                </Grid.Column>
                </Grid>
                );
        } else {
            return (          
                <Grid centered >
                
                    <Grid.Column mobile={16} tablet={12} computer={12}>
                    <Button color={goLiveButtonColor} size="small" type="submit" onClick={this.toggleLiveClick}>
                    {goLiveButtonText}
                    </Button>
                    
                  </Grid.Column>
                
                </Grid>
                );
        }
        
        }
    }
    
    const LiveAdminPage = compose(
        withRouter,
        withFirebase,
    )(LivePage);
const condition = authUser => !!authUser;
    
    export default  withAuthorization(condition)(LiveAdminPage);
    