import React from 'react';
import * as ROUTES from '../../constants/routes';
import { AuthUserContext, withAuthorization } from '../Session';
import { Link, withRouter } from 'react-router-dom';
import {
    Button,
    Form,
    Grid,
    Header,
    Message,
    Segment,
    Divider,
    List,
    InputOnChangeData
  } from 'semantic-ui-react';
import ReactPixel from 'react-facebook-pixel';
const advancedMatching = {  }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};
ReactPixel.init('738722759854909', {}, options);
ReactPixel.pageView();
ReactPixel.trackCustom("PurchaseSuccess", "true");

const PaymentSuccessWithVideoPage = () => (
  
  <Grid centered >
        <Grid.Column mobile={16} tablet={12} computer={8} centered>
          <Segment color="green" padded>
         
          <Header as="h1" textAlign="center"color="green">Purchase complete</Header>
    
                <Divider section />
                <Segment.Inline centered>
                    <Header as="h3" textAlign="left">To receive your personalized video message:</Header>
            <List size="large" relaxed>
            <List.Item>
                <List.Content>1. Tell us what you would like Haley to include in her message by completing <a href="https://www.tappityapp.com/success" target="_blank">this form</a></List.Content>
                </List.Item>
                <List.Item>
                <List.Content>2. We'll email you the video message by July 3rd, 2021</List.Content>
                </List.Item>
                    </List>
                    
        </Segment.Inline>
        <br/>
            <Segment.Inline centered>
                    <Header as="h3" textAlign="left">To access your membership:</Header>
            <List size="large" relaxed>
            <List.Item>
                <List.Content>1. Open Tappity on your device</List.Content>
                </List.Item>
                <List.Item>
                <List.Content>2. Tap on "Menu" on the homescreen</List.Content>
                </List.Item>
                <List.Item>
                <List.Content>3. Tap on "Sign In"</List.Content>
                </List.Item>
                <List.Item>
                <List.Content>4. Enter your Tappity account email address and password</List.Content>
            </List.Item>
            
                    </List>
                    
        </Segment.Inline>
        
                <Divider section />
                If you have any issues, feel free to contact us at hey@tappityapp.com<br/><br/>
                
                <Link to={ROUTES.ACCOUNT}>Go to my account</Link>
                <br/><br/>
            
          </Segment>
        </Grid.Column>
      </Grid>
);


export default PaymentSuccessWithVideoPage;
