import React, { useState, Component } from 'react';
import { AuthUserContext, withAuthorization, withAuthentication } from '../Session';
import {
    Button,
    Form,
    Grid,
    Header,
    Message,
    Segment,
    Divider,
    List,
    Image,
    Accordion,
    Icon,
    InputOnChangeData
} from 'semantic-ui-react';
import { ReferCodeBoxForm } from '../ReferCodeBox';
import FaqBoxPage from '../FaqBox';
import Confetti from 'react-confetti'
import Cookies from 'universal-cookie';
import * as ROUTES from '../../constants/routes';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';

const strikeStyle = {
    textDecoration: "line-through",
    opacity: "0.4",
    fontSize: "10px !important"
};

const yearNormalDiscountPriceID = "price_1H4uyJATKVrws6NtrTPygCUP";
const yearReferPriceID = "price_1H4uu1ATKVrws6NtHTXEQgFV";
const quarterNormalDiscountPriceID = "plan_Gu5SclinquCi8D";
const containerStyle = { backgroundImage: "url('./subscription-bg-gray.jpg') !important" }

const current = new Date();
const nextYear = new Date();

const advancedMatching = {  }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};

nextYear.setFullYear(current.getFullYear() + 1);

ReactPixel.init('738722759854909', {}, options);
ReactPixel.pageView();
ReactPixel.trackCustom("ViewYearPlan", "true");

class ReferPlansPage extends Component {
    
    constructor(props) {
        super(props);  
        let refer = ""
        let pathName = window.location.pathname
        let displayInv = "none"
        let defaultImage = "1-year-plan-discount.png"
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const urlrefer = urlParams.get('refer')
        let confettiAmount = 0
        const cookies = new Cookies();
        cookies.set('price', yearNormalDiscountPriceID, { path: '/', expires: nextYear });
        const cookieRefer = cookies.get('refer');
        let priceID = yearNormalDiscountPriceID
        let comesWithFreeVideo = false
        
        
        if (urlrefer != undefined && urlrefer != null) {
            refer = urlrefer
            if (urlrefer != undefined && urlrefer.toUpperCase().includes("TAP") && urlrefer.length > 6) {
                
                defaultImage = "1-year-plan-refer-discount.png"
                const cookies = new Cookies();
                cookies.set('price', yearReferPriceID, { path: '/', expires: nextYear });
                cookies.set('refer', urlrefer, { path: '/', expires: nextYear });
                confettiAmount = 100
                priceID = yearReferPriceID
                displayInv = "block"
                if (urlrefer == "TAP-TIVBSL") {
                    comesWithFreeVideo = true
                    defaultImage = "1-year-plan-free-video-valentines-day.png"
                }
            }
        }
        else if (cookieRefer != undefined && cookieRefer != null && cookieRefer.toUpperCase().includes("TAP") && cookieRefer.length > 6) {
            defaultImage = "1-year-plan-refer-discount.png"
            const cookies = new Cookies();
            cookies.set('price', yearReferPriceID, { path: '/', expires: nextYear });
            cookies.set('refer', urlrefer, { path: '/', expires: nextYear });
            confettiAmount = 100
            refer = cookieRefer
            priceID = yearReferPriceID
            displayInv = "block"
        }
       
        if (refer != null && refer != undefined && refer.toUpperCase().includes("TAP")) {
            ReactGA.pageview(pathName + "?refer=" + refer);
            displayInv = "block"
        } else {
            ReactGA.pageview(pathName);
            displayInv = "none"
        }
        confettiAmount = 100
        this.state = {
            signedin: props.firebase?.auth?.currentUser,
            referCode: refer,
            productImage: defaultImage,
            confettiSize: confettiAmount,
            price: priceID,
            displayInvite: displayInv,
            comesWithFreeVideo: comesWithFreeVideo
        };
        
        this.submitClick = this.submitClick.bind(this)

    }
    
    componentWillUnmount() {
        
    }
    
    componentDidMount() {
        
        
    }

    submitClick() {
   
        if (this.props.firebase?.auth?.currentUser) {
            this.props.history.push(ROUTES.CHECKOUT);
        }
        else {
            this.props.history.push(ROUTES.SIGN_UP);
        }
        
    }
    

    render() {
        let signedin = this.props.firebase?.auth?.currentUser;
        let buttonText = <p>Sign in or <br />Create an account to get offer</p>
        let headerText = " "
        console.log("is auth?")
        let bonusItem = ""
        let smallPrint = ""

        if (signedin) {
            buttonText = "Subscribe now"
        }
        
        if (this.state.referCode != null && this.state.referCode.length > 5) {
            buttonText = "Claim gift"
        }

        if (this.state.comesWithFreeVideo) {
            buttonText = "Checkout"
            headerText =
                <Segment inverted style={{background: "#E62139", padding: "14px 20px"}}>
                <List >
                    <List.Item as="h3">
                        <List.Icon color="white" name='heart' size="large" style={{paddingTop: "4px"}}/>
                        <List.Content>We'll send your child a personalized video-gram from Haley for Valentine's Day with your purchase of a subscription today!</List.Content>
                    </List.Item>
                </List>
                </Segment>;
            bonusItem =
                <List.Item>
                <List.Icon color="red" name='gift' />
                <List.Content>Personalized video from Haley</List.Content>
            </List.Item>
            smallPrint = <p>Offer expires 2/11/2020, 11:59PM PT<br/>We will email your personalized video message by Feb 14th.</p>
        }
        else if (this.state.referCode != null && this.state.referCode.length > 5) {
            headerText = <Header as="h2" textAlign="center" style={{display: this.state.displayInvite}}>Welcome! You've received a special deal from a friend!</Header>
        }

        return (          
            <Grid centered >
            <Confetti
            width={2000}
            height={2000}
            numberOfPieces={this.state.confettiSize}
                />
                <Grid.Column mobile={16} tablet={12} computer={12}>
                    
                    {headerText}
                
              </Grid.Column>
            <Grid.Column mobile={16} tablet={12} computer={6}>
            
            <Segment style={containerStyle} padded>
            
            <Image src={this.state.productImage} />
            
            <br/><br/>
            <Segment.Inline>
                <List size="large" relaxed>
                            
            <List.Item>
            <List.Icon color="green" name='check circle' />
            <List.Content>Growing library of 300+ science lessons</List.Content>
            </List.Item>
        
            <List.Item>
            <List.Icon color="green" name='check circle' />
            <List.Content>5+ new lessons per week</List.Content>
            </List.Item>
            <List.Item>
            <List.Icon color="green" name='check circle' />
            <List.Content>Access on an unlimited number of devices</List.Content>
                                </List.Item>
                                <List.Item>
            <List.Icon color="green" name='check circle' />
            <List.Content>Create unlimited profiles</List.Content>
                                </List.Item>
                                {bonusItem}             
            </List>
            </Segment.Inline>
            
            <br/><br/>
            
            <Button color="blue" fluid size="large" type="submit" onClick={this.submitClick}>
            {buttonText}
            </Button>

            <br/>
            
                    </Segment>
                    {smallPrint}
            </Grid.Column>
            
            <Grid.Column mobile={16} tablet={12} computer={6}>
            <Segment style={{padding:"0px ",overflow:"hidden", marginBottom: "26px"}}>
                    <a href="http://tappityapp.com" target="_blank"><Image src="learn-more3.png"  /></a>
            <Header as="h3" textAlign="center"style={{display: "none"}}>Tappity is the #1 science app for curious kids. <a href="http://tappityapp.com" target="_blank">Learn more</a></Header>          
            </Segment>
            
                    <FaqBoxPage />
                    
            
            <br/>
            
            </Grid.Column>
            </Grid>
            );
        }
    }
    
    export default withAuthentication(ReferPlansPage);
    