import React, { useState, Component } from 'react';
import { AuthUserContext, withAuthorization, withAuthentication } from '../Session';
import {
    Button,
    Form,
    Grid,
    Header,
    Message,
    Segment,
    Divider,
    List,
    Image,
    Accordion,
    Icon,
    InputOnChangeData
} from 'semantic-ui-react';
import LifetimeFaqBoxPage from '../LifetimeFaqBox';
import Cookies from 'universal-cookie';
import * as ROUTES from '../../constants/routes';
import ReactGA from 'react-ga';

const strikeStyle = {
    textDecoration: "line-through",
    opacity: "0.4",
    fontSize: "10px !important"
};

const lifetimeDiscountPriceID = "price_1H4U7hATKVrws6NthkStS4kW";
const containerStyle = { backgroundImage: "url('./subscription-bg-gray.jpg') !important" }

const current = new Date();
const nextYear = new Date();

nextYear.setFullYear(current.getFullYear() + 1);

class ReferPlansPage extends Component {
    
    constructor(props) {
        super(props);  

        let defaultImage = "lifetime-discount.png"
        let pathName = window.location.pathname
        ReactGA.pageview(pathName);
        const cookies = new Cookies();
        cookies.set('price', lifetimeDiscountPriceID, { path: '/', expires: nextYear });
        let priceID = lifetimeDiscountPriceID

        this.state = {
            signedin: props.firebase?.auth?.currentUser,
            productImage: defaultImage,
            price: priceID
        };
        
        this.submitClick = this.submitClick.bind(this)

    }
    
    componentWillUnmount() {
        
    }
    
    componentDidMount() {
        
    }
    
    submitClick() {
        const cookies = new Cookies();
        cookies.set('price', lifetimeDiscountPriceID, { path: '/', expires: nextYear });
        
        if (this.props.firebase?.auth?.currentUser) {
            this.props.history.push(ROUTES.CHECKOUT);
        }
        else {
            this.props.history.push(ROUTES.SIGN_UP);
        }
        
    }
    
    render() {
        let signedin = this.props.firebase?.auth?.currentUser;
        let buttonText = <p>Sign in or <br />Create an account to get offer</p>
        
        console.log("is auth?")
        
        if (signedin) {
            buttonText = "Buy now"
        }

        return (          
            <Grid centered >
            <Grid.Column mobile={16} tablet={12} computer={6}>
            <Segment style={containerStyle} padded>
            <Image src={this.state.productImage} />
            
            <br/><br/>
            <Segment.Inline>
            <List>
            <List.Item as="h3">
            <List.Icon color="green" name='check circle' />
            <List.Content>Learn about 100+ science topics</List.Content>
            </List.Item>
            <List.Item as="h3">
            <List.Icon color="green" name='check circle' />
            <List.Content>Get unlimited access to our lessons</List.Content>
            </List.Item>
            <List.Item as='h3'>
            <List.Icon color="green" name='check circle' />
            <List.Content>Receive weekly content updates</List.Content>
            </List.Item>
            <List.Item as="h3">
            <List.Icon color="green" name='check circle' />
            <List.Content>Access Tappity on unlimited devices</List.Content>
            </List.Item>
            </List>
            </Segment.Inline>
            
            <br/><br/>
            
            <Button color="blue" fluid size="large" type="submit" onClick={this.submitClick}>
            {buttonText}
            </Button>

            <br/>
            
            </Segment>
            </Grid.Column>
            
            <Grid.Column mobile={16} tablet={12} computer={6}>
            
            <LifetimeFaqBoxPage />
            
            <br/>
            
            </Grid.Column>
            </Grid>
            );
        }
    }
    
    export default withAuthentication(ReferPlansPage);
    