import React from 'react';
import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

/*interface AuthUser {
  uid: string;
  email: string;
}

interface State {
  authUser: AuthUser | null;
}*/

const withAuthentication = (Component) => {
	
  class WithAuthentication extends React.Component { //<{}, {}, any>

    listener;
    

    constructor(props) {
      super(props);
      this.state = {
        authUser: null,
      };
    }

    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        (authUser) => {

          if (authUser) {
            this.props.firebase
              .user(authUser.uid)
              .once('value')
              .then((snapshot) => {
                const dbUser = snapshot.val();
                console.log("authentication")
                
                authUser = {
                  uid: authUser.uid,
                  email: authUser.email,
                  ...dbUser,
                };
                authUser
                  ? this.setState({ authUser })
                  : this.setState({ authUser: null });
                console.log(authUser)
               
              });
          }
        },
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return withFirebase(WithAuthentication);

};

export default withAuthentication;