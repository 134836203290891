import React, { useState, Component } from 'react';
import { AuthUserContext, withAuthorization, withAuthentication } from '../Session';
import {
    Button,
    Form,
    Grid,
    Header,
    Message,
    Segment,
    Divider,
    List,
    Image,
    Accordion,
    Icon,
    InputOnChangeData
} from 'semantic-ui-react';
import ReactGA from 'react-ga';
import { ReferCodeBoxForm } from '../ReferCodeBox';
import FaqBoxPage from '../FaqBox';
import Cookies from 'universal-cookie';
import * as ROUTES from '../../constants/routes';

const strikeStyle = {
    textDecoration: "line-through",
    opacity: "0.4",
    fontSize: "10px !important"
};

const quarterNormalDiscountPriceID = "plan_Gu5SclinquCi8D";
const containerStyle = { backgroundImage: "url('./subscription-bg-gray.jpg') !important" }

const current = new Date();
const nextYear = new Date();

nextYear.setFullYear(current.getFullYear() + 1);

class PlansPage extends Component {
    
    constructor(props) {
        super(props);  
        let refer = ""
        let defaultImage = "plan-image.png"
        const queryString = window.location.search;
        let pathName = window.location.pathname
        ReactGA.pageview(pathName);

        const urlParams = new URLSearchParams(queryString);
        const urlrefer = urlParams.get('refer')
        let comesWithFreeVideo = false

        if (urlrefer != undefined && urlrefer != null) {
            refer = urlrefer
            if (urlrefer == "TAP-TIVBSL") {
                comesWithFreeVideo = true
            }
            else if (urlrefer.toUpperCase().includes("TAP") && urlrefer.length > 6) {
                defaultImage =  "3-month-plan-refer-discount.png"
            }
            
        }

        this.state = {
            signedin: props.firebase?.auth?.currentUser,
            referCode: urlrefer,
            productImage: defaultImage,
            comesWithFreeVideo: comesWithFreeVideo
        };

        const cookies = new Cookies();
        cookies.set('price', quarterNormalDiscountPriceID, { path: '/' });
        cookies.set('refer', urlrefer, { path: '/', expires: nextYear });
        this.getPromoCodeFromInput = this.getPromoCodeFromInput.bind(this);
        this.submitClick = this.submitClick.bind(this)

    }
    
    componentWillUnmount() {
        
    }
    
    componentDidMount() {
        
    }
    
    getPromoCodeFromInput(input){
        console.log(input)
        if (input.length > 6) {
            this.setState({ referCode: input, productImage: "3-month-plan-refer-discount.png" });
        }
       
        
    }
    
    submitClick() {
        const cookies = new Cookies();
        cookies.set('price', quarterNormalDiscountPriceID, { path: '/', expires: nextYear });
        
        if (this.props.firebase?.auth?.currentUser) {
            this.props.history.push(ROUTES.CHECKOUT);
        }
        else {
            this.props.history.push(ROUTES.SIGN_UP);
        }
        
    }

    render() {
        let signedin = this.props.firebase?.auth?.currentUser;
        let buttonText = <p>Sign in or <br />Create an account to get offer</p>
        let headerText = " "
        console.log("is auth?")
        let bonusItem = ""
        let smallPrint = ""
        
        if (signedin) {
            buttonText = "Subscribe now"
        }
        
        if (this.state.comesWithFreeVideo) {
            buttonText = "Checkout"
            headerText =
                <Segment inverted style={{background: "#E62139", padding: "14px 20px"}}>
                <List >
                    <List.Item as="h3">
                        <List.Icon color="white" name='sun' size="large" style={{paddingTop: "4px"}}/>
                        <List.Content>Summer of Science Special: With your purchase today, we'll send your child a personalized video message from Haley!</List.Content>
                    </List.Item>
                </List>
                </Segment>;
            bonusItem =
                <List.Item>
                <List.Icon color="red" name='gift' />
                <List.Content>Personalized video from Haley</List.Content>
            </List.Item>
            smallPrint = <p>Offer expires 6/23/2021, 11:59PM PT.<br/>We will email your personalized video message by July 3rd, 2021.</p>
        }
        else if (this.state.referCode != null && this.state.referCode.length > 5) {
            headerText = <Header as="h2" textAlign="center" style={{display: this.state.displayInvite}}>Welcome! You've received a special deal from a friend!</Header>
        }
        

        return (
            <Grid centered >
                <Grid.Column mobile={16} tablet={12} computer={12}>
                    
                    {headerText}
                
              </Grid.Column>
            <Grid.Column mobile={16} tablet={12} computer={6}>
                
            <Segment style={containerStyle} padded>
            
            <Image src={this.state.productImage} />
            
            <br/><br/>
            <Segment.Inline>
            <List size="large" relaxed>
            <List.Item>
            <List.Icon color="green" name='check circle' />
            <List.Content>Learn about 100+ science topics</List.Content>
            </List.Item>
            <List.Item>
            <List.Icon color="green" name='check circle' />
            <List.Content>Get unlimited access to our lessons</List.Content>
            </List.Item>
            <List.Item>
            <List.Icon color="green" name='check circle' />
            <List.Content>Receive weekly content updates</List.Content>
            </List.Item>
            <List.Item>
            <List.Icon color="green" name='check circle' />
            <List.Content>Access Tappity on unlimited devices</List.Content>
                                </List.Item>
                                {bonusItem}
            </List>
            </Segment.Inline>
            
            <br/><br/>
                        
            <Button color="blue" fluid size="large" type="submit" onClick={this.submitClick}>
            {buttonText}
            </Button>
           
            <br/>
            
                    </Segment>
                    {smallPrint}
            </Grid.Column>
            
            <Grid.Column mobile={16} tablet={12} computer={6}>
           
            
            <FaqBoxPage />
            
            <br/>
            
            </Grid.Column>
            </Grid>
            );
        }
    }
    
    export default withAuthentication(PlansPage);
    