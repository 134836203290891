import React from "react";
import { render } from "react-dom";

const footerStyle = {
  
  fontSize: "14px",
  color: "gray",
  borderTop: "1px solid #E7E7E7",
  textAlign: "center",
  padding: "20px",
  left: "0",
    bottom: "0",
    marginTop: "100px",
  height: "100px",
    width: "100%",
  
};

const phantomStyle = {
  display: "inline-block",
  padding: "20px",
  height: "100px",
  width: "100%"
};

function Footer() {
  return (
    <div>
      <div style={phantomStyle} />
      <div style={footerStyle}>Copyright © 2020 Tappity, Inc. All rights reserved. <a href="https://www.tappityapp.com/terms">Terms of Use</a> and <a href="https://www.tappityapp.com/privacy">Privacy Policy</a></div>
    </div>
  );
}

export default Footer;