import React, { Component } from 'react';
import { withFirebase } from '../Firebase';

import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
} from 'semantic-ui-react';

const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
  error: null,
};

class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }
  onSubmit = event => {
    const { passwordOne } = this.state;
    this.props.firebase
      .doPasswordUpdate(passwordOne)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });
    event.preventDefault();
  };
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const { passwordOne, passwordTwo, error } = this.state;
    const isInvalid =
      passwordOne !== passwordTwo || passwordOne === '';
    return (
      <Grid centered>
        <Grid.Column mobile={16} tablet={12} computer={8}>
          <Segment padded>
            <Header as="h3" textAlign="center">Change password</Header>
            <Form size="large" onSubmit={this.onSubmit}>
              <Form.Input
                fluid
                label = "New password"
                name="passwordOne"
                value={passwordOne}
                onChange={this.onChange}
                type="password"
                placeholder="New password"
              />
              <Form.Input
                fluid
                label = "Re-type password"
                name="passwordTwo"
                value={passwordTwo}
                onChange={this.onChange}
                type="password"
                placeholder="New password"
              />
              <Button disabled={isInvalid} color="blue" fluid size="large"  type="submit">
                Submit
              </Button>
              {error && <p>{error.message}</p>}
            </Form>
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }
}

export default withFirebase(PasswordChangeForm);