import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { SignInLink } from '../SignIn';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import Cookies from 'universal-cookie';
import ReactGA from 'react-ga';
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
} from 'semantic-ui-react';
import ReactPixel from 'react-facebook-pixel';

const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: true, // enable logs
};

const SignUpPage = () => (
  <div>
    <SignUpForm />
    <SignInLink />
  </div>
);

let INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  error: null,
  displayInvite: "none",
  isOffer: false
};


const halfYearGiftPriceID = "price_1HoCe2ATKVrws6NtAAse1hfJ";

class SignUpFormBase extends Component {

  constructor(props) {
    super(props);
    
    const cookies = new Cookies();
    const cookieRefer = cookies.get('refer')

    if (cookieRefer != null && cookieRefer != undefined && cookieRefer.toUpperCase().includes("TAP")) {
      INITIAL_STATE.displayInvite = "block"
      INITIAL_STATE.isOffer = true
    }
    this.state = { ...INITIAL_STATE };
    
    
    let pathName = window.location.pathname
        ReactGA.pageview(pathName);
  }

  makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  onSubmit = event => {
    const { username, email, passwordOne } = this.state;
    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        ReactPixel.init("738722759854909", undefined, options);
        
        ReactPixel.trackCustom("WebAppSignUp", "true");
        return this.props.firebase
        .user(authUser.user.uid)
        .set({
          username,
          email,
          "account-created-web":true,
          "referral-code2":"TAP-" + this.makeid(7),
          "account-created":this.props.firebase.serverValue.TIMESTAMP,
        })
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        const cookies = new Cookies();
        const cookiePriceID = cookies.get('price')
        const cookieRefer = cookies.get('refer')

        if (cookiePriceID != null) {
          if (cookiePriceID == halfYearGiftPriceID) {
            this.props.history.push(ROUTES.CHECKOUT);
          } else {
            this.props.history.push(ROUTES.CHECKOUT);
          }
          
        } 
        else {
          this.props.history.push(ROUTES.PLANS);
        }
      })
      .catch(error => {
        this.setState({ error });
      });
    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      error,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === ''
    let redeemMessage = ""
    
    if (this.state.isOffer) {
      redeemMessage =
        <Segment padded>
        <Header as="h3" textAlign="left"  style={{display: this.state.displayInvite}}>
             Create an account below or <Link to={ROUTES.SIGN_IN}>sign in</Link> to an existing account to redeem this offer and checkout.
            </Header>
      </Segment>
    }
    

    return (
      <Grid centered>
        <Grid.Column mobile={16} tablet={12} computer={8}>
          {redeemMessage}
          <Segment padded>
            <Header as="h1" textAlign="center">Create an account</Header>
            
      
            <Form size="large" onSubmit={this.onSubmit}>
              <Form.Input
                fluid
                label = "Email address"
                name="email"
                icon="user"
                iconPosition="left"
                value={email}
                onChange={this.onChange}
                type="text"
                placeholder="Email"
              />
              <Form.Input
                fluid
                label = "Password"
                icon="lock"
                iconPosition="left"
                name="passwordOne"
                value={passwordOne}
                onChange={this.onChange}
                type="password"
                placeholder="Password"
              />
              <Form.Input
                fluid
                label = "Re-type Password"
                icon="lock"
                iconPosition="left"
                name="passwordTwo"
                value={passwordTwo}
                onChange={this.onChange}
                type="password"
                placeholder="Password"
              />
              <Button disabled={isInvalid} color="blue" fluid size="large"  type="submit">
                Continue
              </Button>
              {error && <p>{error.message}</p>}
            </Form>
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }
}

const SignUpLink = () => (
  <Grid centered columns={2}>
    <Grid.Column>
      <p>
        Don't have an account? <Link to={ROUTES.SIGN_UP}>Create an account</Link>
      </p>
    </Grid.Column>
  </Grid>
);

const SignUpForm = compose(
  withRouter,
  withFirebase,
)(SignUpFormBase);

export default SignUpPage;

export { SignUpForm, SignUpLink };

