import React, { useState } from 'react';
import * as ROUTES from '../../constants/routes';
import { AuthUserContext, withAuthorization } from '../Session';
import { Link, withRouter } from 'react-router-dom';
import {
    Button,
    Form,
    Grid,
    Header,
    Message,
    Segment,
    Divider,
    List,
    Image,
    Accordion,
    Icon,
    InputOnChangeData
} from 'semantic-ui-react';

function InfluencerFaqBoxPage(props) {
    
    let [currentIndex, setCurrentIndex] = useState(0);
    
    return (
        <Accordion styled padded>
        
        <Accordion.Title
        active={currentIndex === 0}
        index={0}
        onClick={() => setCurrentIndex(0)}
        >
        <Header as="h3" textAlign="center">FAQ</Header>
        <Icon name='dropdown' />
        How does it work?
        </Accordion.Title>
        <Accordion.Content active={currentIndex === 0}>
                <p>
                    1) Share your experience with Tappity online or offline along with your special Tappity link every 3 months or more on any platform of your choice, including Facebook, Instagram, Pinterest, and TikTok. 2) For every purchase of a new subscription that comes through your link, you’ll earn $10.
                     
        </p>
        </Accordion.Content>
        
       
        
        <Accordion.Title
        active={currentIndex === 2}
        index={2}
        onClick={() => setCurrentIndex(2)}
        >
        <Icon name='dropdown' />
        What does my audience get?
        </Accordion.Title>
        <Accordion.Content active={currentIndex === 2}>
        <p>
        Through your special link, they can purchase a Tappity subscription for 33% off or $49.99 (normally: $74.99).
        </p>
        </Accordion.Content>
        <Accordion.Title
        active={currentIndex === 3}
        index={3}
        onClick={() => setCurrentIndex(3)}
        >
        <Icon name='dropdown' />
        How do I earn the referral bonus?
        </Accordion.Title>
        <Accordion.Content active={currentIndex === 3}>
        <p>
        You earn a referral bonus ($10) whenever someone purchases a subscription through the special affiliate link created just for you. At the end of each month, we calculate the total number of referrals and issue a payment for them. Note: If your referral requests a refund, a referral bonus will not be issued.
        </p>
        </Accordion.Content>
        
        
        <Accordion.Title
        active={currentIndex === 4}
        index={4}
        onClick={() => setCurrentIndex(4)}
        >
        <Icon name='dropdown' />
        What perks can I earn?
        </Accordion.Title>
        <Accordion.Content active={currentIndex === 4}>
        <p>
        In addition to the referral fees, you have the chance to earn free Tappity merchandise, cash bonuses, and free gifts (e.g. an iPad) at different milestones. 
        </p>
        </Accordion.Content>
        
        <Accordion.Title
        active={currentIndex === 5}
        index={5}
        onClick={() => setCurrentIndex(5)}
        >
        <Icon name='dropdown' />
        How does the payout work?
        </Accordion.Title>
        <Accordion.Content active={currentIndex === 5}>
        <p>
        Referrals will be tallied up at the end of each month. Payouts will happen on the 15th day in the following month. For example, if you referred 12 people in November, we will pay out $220 ($120 for each referral plus a $100 cash bonus) on December 15th through PayPal or Bill.com.
        </p>
        </Accordion.Content>
        
        <Accordion.Title
        active={currentIndex === 6}
        index={6}
        onClick={() => setCurrentIndex(6)}
        >
        <Icon name='dropdown' />
        What will I need to do as a Tappiteer?
        </Accordion.Title>
        <Accordion.Content active={currentIndex === 6}>
        <p>
        We’re leaving it up to you as a content creator. But we do love genuine and thoughtful reviews about your experience with Tappity, and that can take place on any platform of your choosing. But if you are sharing on Facebook, Instagram, Twitter, or TikTok, we’d like for you to tag us in these posts (@tappityapp) and include the hashtag (#tappiteer), so we can re-share. Please follow any FTC advertising disclosures.We’re leaving it up to you as a content creator. But we do love genuine and thoughtful reviews about your experience with Tappity, and that can take place on any platform of your choosing. But if you are sharing on Facebook, Instagram, Twitter, or TikTok, we’d like for you to tag us in these posts (@tappityapp) and include the hashtag (#tappiteer), so we can re-share. Please follow any FTC advertising disclosures.
        </p>
        </Accordion.Content>
        
        </Accordion>
        
        );
    }
    
    
    export default InfluencerFaqBoxPage;