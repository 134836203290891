export const LANDING = '/landing';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/pw-forget';
export const ENTER_PROMO_CODE = '/enter-promo';
export const USER_INFO = '/user-info';
export const PLANS = '/plans';
export const REFER_PLANS = '/referplans';
export const PLANS_LANDING = '/';
export const CHECKOUT = '/checkout'
export const PAYMENT_SUCCESS = '/paymentsuccess'
export const PAYMENT_SUCCESS_WITH_VIDEO = '/paymentsuccesswithvideo'
export const REFERRAL_TRACKER = '/referraltracker'
export const LIFETIME_DISCOUNT = '/lifetime'
export const ADMIN_LIVE = '/adminlive'
export const LIVE = '/live'
export const GIFT = '/gift'
export const GIFTSUCCESS = '/giftpurchasesuccess'