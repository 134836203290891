import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import { AuthUserContext, withAuthorization } from '../Session';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import * as $ from 'jquery';
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
  InputOnChangeData
} from 'semantic-ui-react';


function stripeCancelHandler(props) {
  var firebaseStripeCancel = props.firebase.functions.httpsCallable('stripeFunctions-stripeCancelSubscription');
  firebaseStripeCancel().then(function (result) {
    // Read result of the Cloud Function.
    var sanitizedMessage = result.data.text;
    // ...
  });

  /*fetch('https://us-central1-storytime-a93ed.cloudfunctions.net/stripeFunctions-stripeCancelSubscription', {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      uid: uid
    }),
  }).then(function (result) {
    return result.json();
  }).then(function (customer) {
    
  });*/

}

const lightStyle = {
  fontWeight: "400",
  float:"right"
  
};

class UserInfo extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      membership: "Inactive",
      active: false,
      canceled: false,
      email:this.props.firebase?.auth?.currentUser,
      periodEnd: 0,
      masterMember: false
    };
  }

  onSubmit = event => {
    var r = window.confirm("Click OK if you would like to cancel your subscription. You can still access your membership until your current subscription ends.");

    if (r == true) {
      
      stripeCancelHandler(this.props)
    this.setState({
      loading: false,
      membership: "Active",
      active: false,
      canceled: true,
      email:this.props.firebase?.auth?.currentUser,
      periodEnd: this.state.periodEnd,
      masterMember: this.masterMember
    });
    } else {
     
    }
    
    event.preventDefault();
  };

  componentWillUnmount() {
    this.props.firebase.user().off();
  }

  
  componentDidMount() {
    
    let user = this.props.firebase?.auth?.currentUser;
    let sub = "inactive"

    this.props.firebase
      .user(user.uid)
      .child('auto-renew').once('value').then(function (snapshot) {
        console.log(snapshot.val())
        if (snapshot.val() > 0) {
          this.setState({
            loading: false,
            membership: "Active",
            canceled: this.canceled,
            active: true,
            email:this.props.firebase?.auth?.currentUser,
            periodEnd: 0,
            masterMember: this.masterMember
          });
        }
        // ...
      }.bind(this));

    
        this.props.firebase
        .user(user.uid)
        .child('canceled').once('value').then(function (snapshot) {
          console.log(snapshot.val())
          if (snapshot.val() != null && snapshot.val() == 1) {
            this.setState({
              loading: false,
              membership: "Active",
              active: this.active,
              canceled: true,
              email:this.props.firebase?.auth?.currentUser,
              periodEnd: 0,
              masterMember: this.masterMember
            });
          }
          // ...
        }.bind(this));
      
    
      var firebaseGetStripeSubscription = this.props.firebase.functions.httpsCallable('stripeFunctions-stripeGetSubscription');
      firebaseGetStripeSubscription().then(function (result) {
        // Read result of the Cloud Function.
        console.log("get sub result")
        console.log(result)
        console.log(result.data.success)
        if (result.data.success) {
          var subscription = result.data.subscription;
          var canceled = subscription.cancel_at_period_end
          console.log(result)
  
          var utcSeconds = subscription.current_period_end;
          var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
          d.setUTCSeconds(utcSeconds);
  
          this.setState({
            loading: false,
            membership: "Active",
            active: this.active,
            canceled: canceled,
            email: this.props.firebase?.auth?.currentUser,
            periodEnd: (d.getMonth() + 1) + "/" + d.getDate() + "/" + d.getFullYear(),
            masterMember: this.masterMember
          });
        }
        
        
        // ...
      }.bind(this))
      .catch(function(error) {
        // Getting the Error details.
        var code = error.code;
        var message = error.message;
        var details = error.details;
        console.log(message)
        // ...
      });
    

      this.props.firebase
      .user(user.uid)
        .child('master-member').once('value').then(function (snapshot) {
          console.log(
            "master"
          )
        console.log(snapshot.val())
        if (snapshot.val() != null && snapshot.val() == 1) {
          this.setState({
            loading: false,
            membership: "Active",
            active: this.active,
            canceled: this.canceled,
            email:this.props.firebase?.auth?.currentUser,
            periodEnd: 0,
            masterMember: true
          });
        }
        // ...
        }.bind(this));
    console.log("period end:")
    console.log(this.state.periodEnd)
    if (this.state.periodEnd == null || this.state.periodEnd == 0) {
      this.props.firebase
      .user(user.uid)
        .child('membership-expire-date').once('value').then(function (snapshot) {
          const now = new Date()
          const secondsSinceEpoch = Math.round(now.getTime() / 1000)
          if (snapshot.val() != null && snapshot.val() > secondsSinceEpoch) {
            console.log(snapshot.val())
            var utcSeconds = snapshot.val();
            var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
            d.setUTCSeconds(utcSeconds);
          this.setState({
            loading: false,
            membership: "Active",
            active: this.active,
            canceled: this.canceled,
            email:this.props.firebase?.auth?.currentUser,
            periodEnd: (d.getMonth() + 1) + "/" + d.getDate() + "/" + d.getFullYear(),
            masterMember: this.masterMember
          });
        }
        // ...
        }.bind(this));
    }
      
      
      //console.log(this.props.firebase.user.child('ubscription-id'))
     /* db.ref('users/' + uid).child('subscription-id').once('value')).val();

      */


  };

  render() {

    let renewStatusString = "No"
    console.log("renew?")
    console.log(this.state.canceled)
    if (this.state.canceled == 0) {
      renewStatusString = "yes"
    }

    let renewStatus = <Header as="h4" textAlign="left">Set to auto-renew? <span style={lightStyle}>{renewStatusString}</span></Header>
    let periodEnds = <Header as="h4" textAlign="left">Subscription end date: <span style={lightStyle}>{this.state.periodEnd}</span></Header>
    let button = <Button onClick={this.onSubmit} size="small">Cancel subscription</Button>

    if (this.state.canceled != 0 || this.state.periodEnd == 0) {
      button = " "
    }
    if (this.state.periodEnd == 0) {
      periodEnds = " "
      renewStatus = " "
    }
    if (this.state.masterMember) {
      periodEnds = <Header as="h4" textAlign="left">Subscription end date: <span style={lightStyle}>Lifetime</span></Header>
    }

    return (

      <AuthUserContext.Consumer>
        {authUser => (
          <Grid centered>

            <Grid.Column mobile={16} tablet={12} computer={8}>
            
              <Segment padded>
              <Header as="h3" textAlign="center">Account details</Header>
                <Header as="h4" textAlign="left">Email: <span style={lightStyle}>{this.props.firebase?.auth?.currentUser.email}</span></Header>
                <h4 floated="left">Membership status: <span style={lightStyle}>{this.state.membership}</span></h4>
                {periodEnds}
                {renewStatus}
                {button}
              </Segment>
            </Grid.Column>
          </Grid>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(UserInfo);
