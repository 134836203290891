import React, { useState, Component } from 'react';
import { AuthUserContext, withAuthorization, withAuthentication } from '../Session';
import {
    Button,
    Form,
    Grid,
    Header,
    Message,
    Segment,
    Divider,
    List,
    Image,
    Accordion,
    Icon,
    InputOnChangeData
} from 'semantic-ui-react';
import ReactGA from 'react-ga';
import { ReferCodeBoxForm } from '../ReferCodeBox';
import GiftFaqPage from '../GiftFaq';
import Cookies from 'universal-cookie';
import * as ROUTES from '../../constants/routes';

const strikeStyle = {
    textDecoration: "line-through",
    opacity: "0.4",
    fontSize: "10px !important"
};

const halfYearGiftPriceID = "price_1HoCe2ATKVrws6NtAAse1hfJ";
const containerStyle = { backgroundImage: "url('./subscription-bg-gray.jpg') !important" }

const current = new Date();
const nextYear = new Date();

nextYear.setFullYear(current.getFullYear() + 1);

class GiftPage extends Component {
    
    constructor(props) {
        super(props);  
        let refer = ""
        let defaultImage = "6-month-gift.png"
        const queryString = window.location.search;

        this.state = {
            signedin: props.firebase?.auth?.currentUser,
            productImage: defaultImage
        };

        const cookies = new Cookies();
        cookies.set('price', halfYearGiftPriceID, { path: '/' });

        this.submitClick = this.submitClick.bind(this)

    }
    
    componentWillUnmount() {
        
    }
    
    componentDidMount() {
        
    }
    
    submitClick() {
        const cookies = new Cookies();
        cookies.set('price', halfYearGiftPriceID, { path: '/', expires: nextYear });
        
        if (this.props.firebase?.auth?.currentUser) {
            this.props.history.push(ROUTES.CHECKOUT);
        }
        else {
            this.props.history.push(ROUTES.SIGN_IN);
        }
        
    }

    render() {
        let signedin = this.props.firebase?.auth?.currentUser;
        let buttonText = <p>Sign in or <br />Create an account to buy</p>
        console.log("is auth?")
        
        if (signedin) {
            buttonText = "Buy now"
        }

        return (
            <Grid centered >
                <Grid.Column mobile={16} tablet={12} computer={12}>
                    
                    
                    <Header as="h2" textAlign="center" style={{display: this.state.displayInvite}}>🎅 Give the gift of curiosity this holiday season! 🎄</Header>
                  </Grid.Column>
            <Grid.Column mobile={16} tablet={12} computer={6}>
            <Segment style={containerStyle} padded>
            
            <Image src={this.state.productImage} />
            
            <br/><br/>
            <Segment.Inline>
            <List>
            <List.Item as="h3">
            <List.Icon color="green" name='check circle' />
            <List.Content>Learn about 100+ science topics</List.Content>
            </List.Item>
            <List.Item as="h3">
            <List.Icon color="green" name='check circle' />
            <List.Content>Access our entire content library--updated weekly</List.Content>
            </List.Item>
            <List.Item as='h3'>
            <List.Icon color="green" name='check circle' />
            <List.Content>Enjoy interactive live science shows 3x/week</List.Content>
            </List.Item>
            <List.Item as="h3">
            <List.Icon color="green" name='check circle' />
            <List.Content>Unlimited kid profiles</List.Content>
                                </List.Item>
                                <List.Item as="h3">
            <List.Icon color="green" name='check circle' />
            <List.Content>Play Tappity on unlimited devices</List.Content>
                                </List.Item>
                                
            </List>
            </Segment.Inline>
            
            <br/><br/>
                        
            <Button color="blue" fluid size="large" type="submit" onClick={this.submitClick}>
            {buttonText}
            </Button>
           
            <br/>
            
            </Segment>
            </Grid.Column>
            
            <Grid.Column mobile={16} tablet={12} computer={6}>
           
            
            <GiftFaqPage />
            
            <br/>
            
            </Grid.Column>
            </Grid>
            );
        }
    }
    
    export default withAuthentication(GiftPage);
    