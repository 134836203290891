import React from 'react';

import { PasswordForgetForm } from '../PasswordForget';
import PasswordChangeForm from '../PasswordChange';
import { AuthUserContext, withAuthorization } from '../Session';
import { EnterPromoCodeForm } from '../EnterPromoCode';
import UserInfo from '../UserInfo';
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
  Divider,
  List,
  Accordion,
  Icon,
  InputOnChangeData
} from 'semantic-ui-react';

const AccountPage = () => (
  
  
  <AuthUserContext.Consumer>
    {authUser => (
      <div>
        <Header as="h1" textAlign="center">My Account</Header>
        
        <UserInfo />
        <EnterPromoCodeForm />
        <PasswordChangeForm />
      </div>
    )}
  </AuthUserContext.Consumer>
);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(AccountPage);