import React from 'react';
import { Link } from 'react-router-dom';

import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import { AuthUserContext } from '../Session';

import { Container, Image, Button, Menu, Segment } from 'semantic-ui-react';

const Navigation = () => (
  <div>
    <AuthUserContext.Consumer>
      {authUser =>
        authUser ? <NavigationAuth /> : <NavigationNonAuth />
      }
    </AuthUserContext.Consumer>
  </div>
);

const NavigationAuth = () => (
  <Menu size="large" stackable>
    
      <Menu.Menu position="left" stackable>
        <Menu.Item as="a" header href="http://tappityapp.com" target="blank">            
          <Image
            size="small"
            src="./tappity-logo.png"
          />
        </Menu.Item>
      </Menu.Menu>
      <Menu.Menu position="right" stackable>
        {/* <Menu.Item as="a" name="home">
          <Link to={ROUTES.HOME}>Home</Link>
        </Menu.Item> */}
     
        <Menu.Item as="a" name="account" href={ROUTES.ACCOUNT}>
        My Account
        </Menu.Item>
        <Menu.Item as="a" name="account" href={ROUTES.REFERRAL_TRACKER}>
        🎁‎ ‎‏‏‎ ‎‏‏‎ ‎Referral Tracker
        </Menu.Item>
        {/* <Menu.Item as="a" name="plans" href={ROUTES.REFER_PLANS}>
          
           <Button color="blue" fluid size="large"  type="submit">
                Plans
            </Button>
        </Menu.Item>*/}
        {/* <Menu.Item as="a" name="admin">
          <Link to={ROUTES.ADMIN}>Admin</Link>
        </Menu.Item> */}
        <Menu.Item as="a" name="admin">
          <SignOutButton />
        </Menu.Item>
      </Menu.Menu>
    
  </Menu>
);

const NavigationNonAuth = () => (
    <Menu size="large" stackable>
      <Menu.Menu position="left" stackable>
      <Menu.Item as="a" header href="http://tappityapp.com" target="blank">            
          <Image
            size="small"
            src="./tappity-logo.png"
          />
        </Menu.Item>
      </Menu.Menu>
      <Menu.Menu position="right" stackable>
     {/*  <Menu.Item as="a" name="plans" href={ROUTES.REFER_PLANS}>
            <Button color="blue" fluid size="large"  type="submit">
                Plans
            </Button>
        </Menu.Item>*/}
      
        <Menu.Item as="a" name="signup" href={ROUTES.SIGN_UP}> 
          Create an account
        </Menu.Item>
        <Menu.Item as="a" name="signin" href={ROUTES.SIGN_IN}>
          Sign in
        </Menu.Item>
      </Menu.Menu>
    </Menu>
);

export default Navigation;