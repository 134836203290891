import React from 'react';
import * as ROUTES from '../../constants/routes';
import { AuthUserContext, withAuthorization } from '../Session';
import { Link, withRouter } from 'react-router-dom';
import {
    Button,
    Form,
    Grid,
    Header,
    Message,
    Segment,
    Divider,
    List,
    InputOnChangeData
  } from 'semantic-ui-react';
import ReactPixel from 'react-facebook-pixel';
const advancedMatching = {  }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};
ReactPixel.init('738722759854909', {}, options);
ReactPixel.pageView();
ReactPixel.trackCustom("PurchaseSuccess", "true");

const GiftPurchaseSuccessPage = () => (
  
  <Grid centered >
        <Grid.Column mobile={16} tablet={12} computer={8} centered>
          <Segment color="green" padded>
         
          <Header as="h1" textAlign="center"color="green">Your Purchase Confirmation</Header>
    
            <Divider section />
            <Segment.Inline centered>
                    <Header as="h2" textAlign="left">You're all set! Check your inbox for more details about the gift membership. Thank you for sharing the joy of learning!</Header>
                    <br/>
           
        </Segment.Inline>
        
                <Divider section />
                If you have any issues, feel free to contact us at hey@tappityapp.com<br/><br/>
                
                <Link to={ROUTES.ACCOUNT}>Go to my account</Link>
                <br/><br/>
            
          </Segment>
        </Grid.Column>
      </Grid>
);


export default GiftPurchaseSuccessPage;