import React, { useState } from 'react';
import * as ROUTES from '../../constants/routes';
import { AuthUserContext, withAuthorization } from '../Session';
import { Link, withRouter } from 'react-router-dom';
import {
    Button,
    Form,
    Grid,
    Header,
    Message,
    Segment,
    Divider,
    List,
    Image,
    Accordion,
    Icon,
    InputOnChangeData
} from 'semantic-ui-react';

function FaqBoxPage(props) {
    
    let [currentIndex, setCurrentIndex] = useState(0);
    
    return (
        <Accordion styled padded>
        
        <Accordion.Title
        active={currentIndex === 0}
        index={0}
        onClick={() => setCurrentIndex(0)}
        >
        <Header as="h3" textAlign="center">FAQ</Header>
        <Icon name='dropdown' />
        How does the subscription work?
        </Accordion.Title>
        <Accordion.Content active={currentIndex === 0}>
        <p>
        As soon as you create an account and purchase this plan, you'll get unlimited access to Tappity's entire content library. Your account will auto-renew at the end of the subscription period. Before renewal, you will receive a renewal reminder email. You can also choose to cancel your subscription at any time.
        </p>
        </Accordion.Content>
        
        <Accordion.Title
        active={currentIndex === 1}
        index={1}
        onClick={() => setCurrentIndex(1)}
        >
        <Icon name='dropdown' />
        Can I cancel my subscription?
        </Accordion.Title>
        <Accordion.Content active={currentIndex === 1}>
        <p>
        Yes. Visit My Account at any time and click on the cancellation link. We do not charge any cancellation fees. As a reminder, unless you cancel your subscription, it will auto-renew. We will send you a reminder email one week before your subscription is up for renewal.
        </p>
        </Accordion.Content>
        
        <Accordion.Title
        active={currentIndex === 2}
        index={2}
        onClick={() => setCurrentIndex(2)}
        >
        <Icon name='dropdown' />
        Why do I need a Tappity account?
        </Accordion.Title>
        <Accordion.Content active={currentIndex === 2}>
        <p>
        By creating an account, you can manage your subscription and reuse your credentials on multiple devices.
        </p>
        </Accordion.Content>
        <Accordion.Title
        active={currentIndex === 3}
        index={3}
        onClick={() => setCurrentIndex(3)}
        >
        <Icon name='dropdown' />
        How many lessons does Tappity offer?
        </Accordion.Title>
        <Accordion.Content active={currentIndex === 3}>
        <p>
        Our growing library contains over 300 experiments, games, lessons, videos, and more, all of which are aligned with the Next Generation Science Standards. New content is added every week.
        </p>
        </Accordion.Content>
        
        
        <Accordion.Title
        active={currentIndex === 4}
        index={4}
        onClick={() => setCurrentIndex(4)}
        >
        <Icon name='dropdown' />
        What topics does Tappity cover?
        </Accordion.Title>
        <Accordion.Content active={currentIndex === 4}>
        <p>
        We've aligned our content with the Next Generation Science Standards for K-5th Grade, and we also cover topics
        that are popular with kids, including Animals, Dinosaurs, and more! For our full list, please check out this page.
        <a href="https://www.tappityapp.com/covered-topics" target="blank"> https://www.tappityapp.com/covered-topics</a>
        </p>
        </Accordion.Content>
        
        <Accordion.Title
        active={currentIndex === 5}
        index={5}
        onClick={() => setCurrentIndex(5)}
        >
        <Icon name='dropdown' />
        Can I use Tappity on multiple devices?
        </Accordion.Title>
        <Accordion.Content active={currentIndex === 5}>
        <p>
        Yes, you can! After you create an account and purchase a subscription, simply use your credentials to login to Tappity on all your devices!
        </p>
        </Accordion.Content>
        
        <Accordion.Title
        active={currentIndex === 6}
        index={6}
        onClick={() => setCurrentIndex(6)}
        >
        <Icon name='dropdown' />
        What devices does Tappity support?
        </Accordion.Title>
        <Accordion.Content active={currentIndex === 6}>
        <p>
        We support all 2016 or later Apple iOS devices. Tappity requires iOS 13.2 or later.
        </p>
        </Accordion.Content>
        
        <Accordion.Title
        active={currentIndex === 7}
        index={7}
        onClick={() => setCurrentIndex(7)}
        >
        <Icon name='dropdown' />
        I have other questions. How can I contact you?
        </Accordion.Title>
        <Accordion.Content active={currentIndex === 7}>
        <p>
        We'd love to hear from you. Please contact us at hey@tappityapp.com.
        </p>
        </Accordion.Content>
        </Accordion>
        
        );
    }
    
    
    export default FaqBoxPage;