import React, { useState, Component } from 'react';
import { AuthUserContext, withAuthorization, withAuthentication } from '../Session';
import {
    Button,
    Form,
    Grid,
    Header,
    Message,
    Segment,
    Divider,
    List,
    Image,
    Accordion,
    Icon,
    InputOnChangeData
} from 'semantic-ui-react';
import Firebase, { withFirebase } from '../Firebase';
import { compose } from 'recompose';
import { Link, withRouter } from 'react-router-dom';

const pollOptionStyle = {
    backgroundColor: "red",
    fontColor: "white",
    width: "120px",
    height: "60px",
    display: "inline-block",
    margin: "20px 10px 40px 0px",
    textAlign: "center",
    padding: "4px",
};
const pollOptionStyle2 = {
    backgroundColor: "blue",
    fontColor: "white",
    width: "120px",
    height: "60px",
    display: "inline-block",
    margin: "20px 10px 40px 0px",
    textAlign: "center",
    padding: "4px",
};

class AdminLivePage extends Component {
    
    constructor(props) {
        super(props);  
        
        this.state = {
            signedin: props.firebase?.auth?.currentUser,
            isLive: false,
            isPolling: false,
            orangeCount: 0,
            blueCount: 0,
            liveViewers: 0,
            loading: true,
            url: ""
        };
        
        const db = this.props.firebase.firestore;
        const dbRealTime = this.props.firebase.db;

        db.collection("tappityLiveEvents").doc("liveEvent1")
        .onSnapshot(function(doc) {
            console.log("Current data: ", doc.data());
            this.setState({loading: false });
        }.bind(this));

        dbRealTime.ref("polls").on('value', function(snapshot) {
            var val = snapshot.val()
            console.log("Current data: ", val);
            this.setState({isPolling: val.visible, orangeCount:  val.orangeCount, blueCount: val.blueCount, loading: false });
        }.bind(this));

        db.collection("seasons").doc("tappity_live")
        .onSnapshot(function(doc) {
            console.log("Current data: ", doc.data());
            this.setState({isLive: doc.data().currentlyLive, loading: false });
        }.bind(this));

        this.toggleLiveClick = this.toggleLiveClick.bind(this)
        this.setComingSoonClick = this.setComingSoonClick.bind(this)
        this.togglePollClick = this.togglePollClick.bind(this)

    }
    
    togglePollClick() {
        const db = this.props.firebase.firestore;
        const dbRealTime = this.props.firebase.db;
        if (this.state.isPolling) { // turning off
            dbRealTime.ref("polls").update({
                visible: !this.state.isPolling
            }, function(error) {
                if (error) {
                  // The write failed...
                } else {
                  // Data saved successfully!
                }
              });
            
        }
        else {
            dbRealTime.ref("polls").set({
                blueCount: 0,
                orangeCount: 0,
                visible: !this.state.isPolling
            }, function(error) {
                if (error) {
                  // The write failed...
                } else {
                  // Data saved successfully!
                }
              });
        }
        
    }
    
    toggleLiveClick() {
        let priority = 0

        if (this.state.isLive) {
            this.setComingSoonClick()
            priority = 130
        }
        const db = this.props.firebase.firestore;
        const liveRef = db.collection("tappityLiveEvents").doc("liveEvent1").set({
            streamVisible: !this.state.isLive,
        }, { merge: true }).catch(function(error) {
            console.log("Error getting document:", error);
        });

        const seasonRef = db.collection("seasons").doc("tappity_live").set({
            currentlyLive: !this.state.isLive,
            priority: priority,
        }, { merge: true }).catch(function(error) {
            console.log("Error getting document:", error);
        });
        
    }

    setComingSoonClick() {
        
        const db = this.props.firebase.firestore;
        const liveRef = db.collection("tappityLiveEvents").doc("liveEvent1").set({
            streamUrl: "https://youtu.be/yWimUms_af4",
        }, { merge: true }).catch(function(error) {
            console.log("Error getting document:", error);
        });
        
    }
    
    onChange = (event, { value }) => {
        this.setState({ url: value });
      };
    
    onSubmit = async (event) => {
      
        console.log(this.state.url)
        const db = this.props.firebase.firestore;
        const liveRef = db.collection("tappityLiveEvents").doc("liveEvent1").set({
            streamUrl: this.state.url,
        }, { merge: true }).catch(function(error) {
            console.log("Error getting document:", error);
        });
        event.preventDefault();
        
    };
    
    render() {
        let goLiveButtonColor = "blue"
        let setStartingSoonText = "Set to starting soon video"
        let goLiveButtonText = "Go live"
        let startPollText = "🤩 Start poll"
        let pollStatus = "🙈 - Poll is NOT showing"

        if (this.state.isLive) {
            goLiveButtonColor = "white"
            goLiveButtonText = "End live event"
        }

        if (this.state.isPolling) {
            startPollText = "🙈 End poll"
            pollStatus = "🤩 - Poll IS showing"
        }
        console.log(this.props.firebase?.auth?.currentUser)
        if (this.props.firebase?.auth?.currentUser.email != "live@tappityapp.com") {
            return (
                <Header as="h2" textAlign="center">Blocked</Header>
            )
        }
        if (this.state.loading) {
            return (
                <Header as="h2" textAlign="center">Loading...</Header>
            )
        }
        if (this.state.isLive) {
            return (          
                <Grid centered >
                
                    <Grid.Column mobile={16} tablet={12} computer={12}>
                    <Button color={goLiveButtonColor} size="small" type="submit" onClick={this.toggleLiveClick}>
                    {goLiveButtonText}
                        </Button>
                        
                    <Button color="blue" size="small" type="submit" onClick={this.setComingSoonClick}>
                    {setStartingSoonText}
                    </Button>
                    
                  </Grid.Column>
                <Grid.Column mobile={16} tablet={12} computer={6}>
                
                        <Segment padded>
                            
                            <Header as="h2" textAlign="left">{pollStatus}</Header>
                            <Segment style={pollOptionStyle}>
                                <Header as="h2" textAlign="center" inverted>{this.state.orangeCount}</Header>
                               
                            </Segment>
                            <Segment style={pollOptionStyle2}>
                                <Header as="h2" textAlign="center" inverted>{this.state.blueCount}</Header>
                               
                            </Segment>
    
                            <Button color="blue" fluid size="Large" type="submit" onClick={this.togglePollClick}>
                    {startPollText}
                    </Button>
                </Segment>
                </Grid.Column>
                    
                <Grid.Column mobile={16} tablet={12} computer={6}>
                    <Segment padded>
                    <Header as="h4" textAlign="center">Update stream</Header>
            <Form size="large" onSubmit={this.onSubmit}>
              <Form.Input fluid label=" " name="url" value={this.state.url} onChange={this.onChange} type="text" placeholder="URL" />
              <Button color="blue" size="small" type="submit"> Submit </Button> 
            </Form>
                </Segment>
                
                </Grid.Column>
                </Grid>
                );
        } else {
            return (          
                <Grid centered >
                
                    <Grid.Column mobile={16} tablet={12} computer={12}>
                    <Button color={goLiveButtonColor} size="small" type="submit" onClick={this.toggleLiveClick}>
                    {goLiveButtonText}
                        </Button>
                        <Button color="blue" size="small" type="submit" onClick={this.setComingSoonClick}>
                    {setStartingSoonText}
                    </Button>
                    
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={12} computer={6}>
                    <Segment padded>
                    <Header as="h4" textAlign="center">Update stream</Header>
            <Form size="large" onSubmit={this.onSubmit}>
              <Form.Input fluid label=" " name="url" value={this.state.url} onChange={this.onChange} type="text" placeholder="URL" />
              <Button color="blue" size="small" type="submit"> Submit </Button> 
            </Form>
                </Segment>
                
                </Grid.Column>
                </Grid>
                );
        }
        
        }
    }
    
    const LiveAdminPage = compose(
        withRouter,
        withFirebase,
    )(AdminLivePage);
const condition = authUser => !!authUser;
    
    export default  withAuthorization(condition)(LiveAdminPage);
    