import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Firebase, { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { AuthUserContext, withAuthorization } from '../Session';
import hbc from '../../images/hbc-logo.jpg';

import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
  InputOnChangeData
} from 'semantic-ui-react';

const EnterPromoCodePage = () => (
  <div>
  <EnterPromoCodeForm />
  </div>
  );
  
  interface Props {
    firebase: Firebase;
  }
  
  interface State {
    code: string;
    error: null | Error;
    
  }
  
  interface PromoCode {
    type: string;
    'used-by-uid': string | 0;
    'usedByUID': string | 0 ;
  }
  
  const INITIAL_STATE = {
    code: '',
    error: null,
  };
  
  class EnterPromoCodeFormBase extends Component<Props, State> {
    
    constructor(props: Props) {
      super(props);
      this.state = { ...INITIAL_STATE };
    }
    
    onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
      const { code } = this.state;
      
      try {
        const uid: string | undefined = this.props.firebase?.auth?.currentUser?.uid;
        
        /*document?.querySelector( "img" )?.classList.add( "hidden" ); */
        
        if(uid === undefined) {
          throw new Error('Not signed in');
        }
        const snapshot: any = await this.props.firebase
        .promoCode(code)
        .once('value');
        
        const dbCode: null | PromoCode = snapshot.val();
        var codeType = "none"
        
        if(code === "year-master-code"){
          codeType = "master"
        }
        else if(code === "lifetime-master-code"){
          codeType = "master"
          await this.props.firebase
          .user(uid)
          .update({
            "master-member": 1, 
          }).then(() => {
            const success = new Error("Master lifetime given")
            this.setState({ error: success });
          });
        }
        else if (code === "month-master-code") {
          codeType = "master"
          const now = new Date()
          const secondsSinceEpoch = Math.round(now.getTime() / 1000)
          const expireDateEpoch = secondsSinceEpoch + 2629743 //add one month
          
          await this.props.firebase
          .user(uid)
          .update({
            "membership-expire-date": expireDateEpoch, 
            "promo-code": code,
          }).then(() => {
            const success = new Error("Master month given")
            this.setState({ error: success });
          });
        }
        else if(code === "quarterly-master-code"){
          codeType = "master"
          const now = new Date()
          const secondsSinceEpoch = Math.round(now.getTime() / 1000)
          const expireDateEpoch = secondsSinceEpoch + 2629743*3 //add one month
          await this.props.firebase
          .user(uid)
          .update({
            "membership-expire-date": expireDateEpoch, 
            "promo-code": code,
          }).then(() => {
            const success = new Error("Master 3 month given")
            this.setState({ error: success });
          });
        }
        else if(code === "annual-master-code"){
          codeType = "master"
          const now = new Date()
          const secondsSinceEpoch = Math.round(now.getTime() / 1000)
          const expireDateEpoch = secondsSinceEpoch + 2629743*12 //add one month
          await this.props.firebase
          .user(uid)
          .update({
            "membership-expire-date": expireDateEpoch, 
            "promo-code": code,
          }).then(() => {
            const success = new Error("Master annual given")
            this.setState({ error: success });
          });
        }
        else if ((dbCode === null || (dbCode["used-by-uid"] !== 0 && dbCode["usedByUID"] !== "0"))) {
          if (dbCode !== null && (dbCode["used-by-uid"] === uid || dbCode["usedByUID"] === uid)) {
            throw new Error('This promo code has already been applied to your account.');
          } else {
            throw new Error('Invalid or used promo code. If you think this is a mistake contact hey@tappityapp.com');
          }
          
        }
        else {
          codeType = dbCode["type"]
        }
        
        if (codeType != "master") {
          await this.props.firebase
          .promoCode(code)
          .update({
            "used-by-uid": uid, //set code used 
            "usedByUID": uid,
            type: codeType,
          });
          const now = new Date()
          const secondsSinceEpoch = Math.round(now.getTime() / 1000)
          let expireDateEpoch = secondsSinceEpoch + 31556926 //add one year
          let successString = "Thank you! We have successfully applied your promo code. A 1 year membership has been added to your Tappity account. To complete the activation: 1) Download Tappity from the Apple App Store 2) Open Tappity & tap on the Parents Menu 3) Tap on Sign In and log in with your credentials 4) Restart Tappity to refresh the app"

          if (code.includes("TAPGIFT")) {
            expireDateEpoch = secondsSinceEpoch + 31556926 / 2 //half year
            successString = "6-month gift membership successfully applied. To complete the activation: 1) Download Tappity from the Apple App Store 2) Tap on sign in and log in with your credentials 3) Your Tappity app is now ready for use with!"
          }

          await this.props.firebase
          .user(uid)
          .update({
            "membership-expire-date": expireDateEpoch, //set code used 
            "promo-code": code,
          }).then(() => {
            const success = new Error(successString)
            this.setState({ error: success });
          });
        }
        
      } catch (error) {
        this.setState({ error });
      }
      event.preventDefault();
      
      // this.props.firebase
      //   .promoCode(code)
      //   .once('value')
      //   .then((snapshot: any) => {
      //     const dbCode: null | PromoCode = snapshot.val();
      //     console.log(dbCode)
      //     if (dbCode === null || dbCode["used-by-uid"] !== 0) {
      //       const error = new Error('Invalid promo code. If you think this is a mistake contact hey@tappityapp.com')
      //       this.setState({ error });
      //     }
      //     else {
      //       this.props.firebase
      //         .promoCode(code)
      //         .set({
      //           "used-by-uid": this.props.firebase.auth.currentUser.uid, //set code used 
      //           type: dbCode["type"]
      //         })
      //         .then(() => {
      //           const now = new Date()
      //           const secondsSinceEpoch = Math.round(now.getTime() / 1000)
      //           const expireDateEpoch = secondsSinceEpoch + 31556926 //add one year
      //           this.props.firebase
      //             .user(this.props.firebase.auth.currentUser.uid)
      //             .update({
      //               "membership-expire-date": expireDateEpoch, //set code used 
      //               type: dbCode["type"]
      //             })
      //             .then(() => {
      //               const error = Error("Promo code accepted. Membership activated. 1) Download Tappity from the app store 2) Open the app & sign in from the parent menu 3) Restart the app")
      //               this.setState({ error });
      //             })
      //             .catch(error => {
      //               this.setState({ error });
      //             });
      //         })
      //         .catch(error => {
      //           this.setState({ error });
      //         });
      //     }
      
      //   })
      //   .catch(error => {
      //     this.setState({ error });
      //   });
    };
    
    onChange = (event: React.ChangeEvent<HTMLInputElement>, { value }: InputOnChangeData) => {
      this.setState({ code: value });
    };
    
    render() {
      const { code, error } = this.state;
      const isInvalid = code === '';
      
      
      return (
        <Grid centered>
        
        <Grid.Column mobile={16} tablet={12} computer={8}>
        <Segment padded>
        <Header as="h3" textAlign="center">Enter promo code</Header>
        <Form size="large" onSubmit={this.onSubmit}>
        <Form.Input
        fluid
        label="Promo Code"
        name="code"
        value={this.state.code}
        onChange={this.onChange}
        type="text"
        placeholder="Promo code"
        />
        
        <Button disabled={isInvalid} color="blue" fluid size="large" type="submit">
        Submit
        </Button>
        <img id="logo" src={hbc} alt="logo" hidden />
        {error && <p>{error.message}</p>}
        </Form>
        </Segment>
        </Grid.Column>
        </Grid>
        );
      }
      
    }
    
    
    export default EnterPromoCodePage;
    const EnterPromoCodeForm = withFirebase(EnterPromoCodeFormBase);
    export { EnterPromoCodeForm };