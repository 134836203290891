import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Firebase, { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { AuthUserContext, withAuthorization } from '../Session';
import Cookies from 'universal-cookie';

import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
  InputOnChangeData
} from 'semantic-ui-react';

const ReferCodeBoxPage = () => (
  <div>
  <ReferCodeBoxForm />
  </div>
  );
  
 
  
  
  class ReferCodeBoxFormBase extends Component {
    
    constructor(props) {
      super(props);

      const cookies = new Cookies();
      const cookieRefer = cookies.get('refer')
      let refer = ""
      let vis = true

      if (cookieRefer != undefined && cookieRefer.toUpperCase().includes("TAP") && cookieRefer.length > 6) {
        vis = false
        refer = cookieRefer
      }
      console.log("cookie in box")
      console.log(cookieRefer)
      
      const INITIAL_STATE = {
        code: refer,
        error: null,
        formVisible: vis
      };

      this.state = { ...INITIAL_STATE };
      this.updateParentWithInput = this.updateParentWithInput.bind(this);
      this.editCode = this.editCode.bind(this)

    }
    
    updateParentWithInput(){
      this.props.inputUpdatedParent("test")
    }
    
    onSubmit = async (event) => {
      const { code } = this.state;
      
      if(code != undefined && code.toUpperCase().includes("TAP")){
        this.props.inputUpdatedParent(code)
        this.setState({ formVisible: false });
      }

      event.preventDefault();
      
    };
    
    onChange = (event, { value }) => {
      this.setState({ code: value });
    };
    
    editCode() {
      this.setState({ formVisible: true });
    }

    render() {
      const { code, error } = this.state;
      const isInvalid = code === '';

      if (this.state.formVisible) {
        return (
          <Segment.Inline>
            <Header as="h4" textAlign="center">Enter referral code</Header>
            <Form size="large" onSubmit={this.onSubmit}>
              <Form.Input fluid label=" " name="code" value={this.state.code} onChange={this.onChange} type="text" placeholder="Referral code" />
              <Button disabled={isInvalid} color="blue" size="small" type="submit"> Submit </Button> {error && <p>{error.message}</p>}
            </Form>
          </Segment.Inline>
          );
      }
      else {
        return (
          <Segment.Inline>
            <Header as="h4" textAlign="center">Referral code accepted: {this.state.code} </Header>
            <Grid>
    <Grid.Column textAlign="center">
              <Button color="white" size="small" type="submit" onClick={this.editCode}> Edit referral code </Button>
              </Grid.Column>
  </Grid>
          </Segment.Inline>
          );
      }
      
      }
      
    }
    
    
    export default ReferCodeBoxPage;
    const ReferCodeBoxForm = withFirebase(ReferCodeBoxFormBase);
    export {ReferCodeBoxForm };