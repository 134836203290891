import React, { useState, Component } from 'react';
import { AuthUserContext, withAuthorization, withAuthentication } from '../Session';
import {
    Button,
    Form,
    Grid,
    Header,
    Message,
    Segment,
    Divider,
    List,
    Image,
    Accordion,
    Icon,
    InputOnChangeData
} from 'semantic-ui-react';

import Confetti from 'react-confetti'
import Cookies from 'universal-cookie';
import ReactGA from 'react-ga';
import ReferralFaqBoxPage from '../ReferralFaqBox';
import InfluencerFaqBoxPage from '../InfluencerFaq';
import {
  FacebookShareButton,
} from "react-share";
import {
  FacebookIcon,
} from "react-share";
const strikeStyle = {
    textDecoration: "line-through",
    opacity: "0.4",
    fontSize: "10px !important"
};

const current = new Date();
const nextYear = new Date();


nextYear.setFullYear(current.getFullYear() + 1);
ReactGA.pageview('/referraltracker');

class ReferralTrackerPage extends Component {
  

    constructor(props) {
        super(props);  
        this.state = {
            loading: true,
          referralCount: 0,
          paidOutCount: 0,
          influencer: 0,
            referralCode: "none",
          member: false,
          buttonText: "Copy URL",
        };
        let pathName = window.location.pathname
        ReactGA.pageview(pathName);
        
    }
    
    componentWillUnmount() {
        
    }
  
    setReferralCode() {
      
    }
  
    componentDidMount() {
        let user = this.props.firebase?.auth?.currentUser;
        let sub = "inactive"
    
        this.props.firebase
          .user(user.uid)
          .once('value').then(function (snapshot) {
              console.log(snapshot.val())
            let att = snapshot.val()
            let totalRefers = snapshot.child("referral-count").val()
            let paidOutCount = snapshot.child("paid-out-count").val()
            let isInfluencer = snapshot.child("influencer").val()
            let referCode = "app.tappityapp.com/referplans?refer=" + snapshot.child("referral-code2").val()
            
            if (totalRefers == undefined || totalRefers == null) {
              totalRefers = 0
            }

            if (paidOutCount == undefined || paidOutCount == null) {
              paidOutCount = 0
            }

            console.log(totalRefers)
            console.log("totalRefers")
            let reward1Earned = true
            let reward2Earned = true
            let reward3Earned = true

            

            if (isInfluencer) {
              if (totalRefers > 9) {
                reward1Earned = false
              }
              if (totalRefers > 19) {
                reward2Earned = false
              }
              if (totalRefers > 49) {
                reward3Earned = false
              }
            } else {
              if (totalRefers > 0) {
                reward1Earned = false
              }
              if (totalRefers > 2) {
                reward2Earned = false
              }
              if (totalRefers > 5) {
                reward3Earned = false
              }
            }

            console.log(reward2Earned )
              this.setState({
                loading: false,
                referralCount: totalRefers,
                referralCode: referCode,
                reward1Earned: reward1Earned,
                reward2Earned: reward2Earned,
                reward3Earned: reward3Earned,
                paidOutCount: paidOutCount,
                isInfluencer: isInfluencer
              });
            
            // ...
          }.bind(this));
    }
    
    copyText = (e) => { 
    /* Get the text field */
    this.textArea.select();
      document.execCommand('copy');
      this.setState({
        buttonText: "👍"
      });
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    //e.target.focus();
   // this.setState({ copySuccess: 'Copied!' });
    };

  claimReward1() {

  }
  claimReward2() {
    
  }
  claimReward2() {
    
  }
    render() {
       
      if(this.state.isInfluencer) {
        return (
          <Grid centered >
            <Confetti
              width={2000}
              height={2000}
              numberOfPieces={this.state.confettiSize}
            />
             
            <Grid.Column mobile={16} tablet={12} computer={6}>
              <Segment padded style={{ background: "#5931EA" }}>
                <Header as="h1" textAlign="left" inverted>Tappity Brand Ambassador Program</Header>
                <Header as="h3" inverted>
                It’s official—you’re a science-advocatin’, curiosity-sparking Tappiteer! Which means you can now earn rewards whenever people purchase a Tappity subscription through your link. (Plus, they get 33% off!)
              </Header>
                <br />
                <Image src="family-ipad.jpg" style={{ borderRadius: "12px" }} />
                <Header as="h3" textAlign="left" inverted>Share your referral link:</Header>
                <p style={{ background: "#E7F4FF", padding: "4px", borderRadius: "4px" }}>{this.state.referralCode}</p>
                
                <Button color="white" inverted size="small" type="submit" onClick={this.copyText} style={{ margin: "0px", float: "left" }}> {this.state.buttonText} </Button>
               
                <FacebookShareButton url={this.state.referralCode} quote={""} className="share" style={{ marginLeft: "20px", float: "left" }}>
                  <Image src="facebook-share-icon-png-9.png" width="82px" />
                </FacebookShareButton>
                <br /> <br />
                <form style={{ opacity: "0", height: "1px" }}><textarea
                  ref={(textarea) => this.textArea = textarea}
                  value={this.state.referralCode}
                /></form>
                
              </Segment>
              <InfluencerFaqBoxPage />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={12} computer={6}>
            
              <Segment padded>
              <Header as="h1" textAlign="left">Referral Tracker:</Header>
                <Header as="h3" textAlign="left">You’ve referred in total: {this.state.referralCount}</Header>
                <Header as="h3" textAlign="left">You’ve received payment for: {this.state.paidOutCount}</Header>
                <Header.Subheader>Paid on the 15th of each month</Header.Subheader>
                </Segment>
                <Segment padded>
                <Header as="h1" textAlign="left">Tappiteer ‎‏Rewards</Header>
                <Header.Subheader>
                <Header as="h3" textAlign="left"> Earn $10 per referral plus the rewards below.</Header>
              </Header.Subheader>
              
                <Divider section />
                <p>
                  <Header as="h4" textAlign="left"><span hidden={this.state.reward1Earned}>👍  ‏‏‎ </span>After 10 referrals...</Header>
                  
                  <Header as="h3" textAlign="left">Earn a $50 cash bonus </Header>
                  </p>
                
                <Divider section />
                <p>
                  <Header as="h4" textAlign="left"><span hidden={this.state.reward2Earned}>👍  ‏‏‎ </span>After 20 referrals...</Header>
                  <Header as="h3" textAlign="left">Earn a $100 cash bonus </Header>
                 
                 </p>
                
                <Divider section />
                <p>
                  <Header as="h4" textAlign="left"><span hidden={this.state.reward3Earned}>👍  ‏‏‎ </span>After 50 referrals...</Header>
                  <Header as="h3" textAlign="left">Earn an iPad!</Header>
                  <Image src="https://cdn.shopify.com/s/files/1/1525/0840/products/iPadmini_SpaceGray_2up_US-EN-PRINT.jpg" width="150px" />
                  
                 </p>
                
              </Segment>
            </Grid.Column>
            
           
          </Grid>
        );
      }
      else {
        return (
          <Grid centered >
            <Confetti
              width={2000}
              height={2000}
              numberOfPieces={this.state.confettiSize}
            />
             
            <Grid.Column mobile={16} tablet={12} computer={6}>
              <Segment padded style={{ background: "#5931EA" }}>
                <Header as="h1" textAlign="left" inverted>Give a gift, receive a gift</Header>
                <Header as="h3" inverted>
                  Give your friends 33% off their first Tappity subscription. When they subscribe to Tappity using your link, you'll begin earning rewards!
              </Header>
                <br />
                <Image src="family-ipad.jpg" style={{ borderRadius: "12px" }} />
                <Header as="h3" textAlign="left" inverted>Share your referral link:</Header>
                <p style={{ background: "#E7F4FF", padding: "4px", borderRadius: "4px" }}>{this.state.referralCode}</p>
                
                <Button color="white" inverted size="small" type="submit" onClick={this.copyText} style={{ margin: "0px", float: "left" }}> {this.state.buttonText} </Button>
               
                <FacebookShareButton url={this.state.referralCode} quote={""} className="share" style={{ marginLeft: "20px", float: "left" }}>
                  <Image src="facebook-share-icon-png-9.png" width="82px" />
                </FacebookShareButton>
                <br /> <br />
                <form style={{ opacity: "0", height: "1px" }}><textarea
                  ref={(textarea) => this.textArea = textarea}
                  value={this.state.referralCode}
                /></form>
                
              </Segment>
              <ReferralFaqBoxPage />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={12} computer={6}>
            
              <Segment padded>
                <Header as="h1" textAlign="left">You've referred {this.state.referralCount} Tappernauts</Header>
                <Header as="h3" textAlign="left">Tappity 🎁  ‏‏‎ ‎‏Rewards</Header>
                <Header.Subheader>
                  Get your first reward when you refer a new subscriber to Tappity.
              </Header.Subheader>
                <Divider section />
                <p>
                  <Header as="h4" textAlign="left"><span hidden={this.state.reward1Earned}>👍  ‏‏‎ </span>After 1 referral...</Header>
                  <Image src="autograph-prize.png" width="200px" />
                  Haley will send your child an autographed photo with a written message!
                  </p>
                <Button color="blue" size="small" disabled={this.state.reward1Earned} type="submit" onClick={this.claimReward1}> Claim reward </Button>
                <Divider section />
                <p>
                  <Header as="h4" textAlign="left"><span hidden={this.state.reward2Earned}>👍  ‏‏‎ </span>After 3 referrals...</Header>
                  <Image src="message-prize.png" width="200px" />
                  A fan of Haley? With this reward, she'll record a personally video message to your child and address them by their name!
                 </p>
                <Button color="blue" size="small" disabled={this.state.reward2Earned} type="submit" onClick={this.claimReward2}> Claim reward </Button>
                <Divider section />
                <p>
                  <Header as="h4" textAlign="left"><span hidden={this.state.reward3Earned}>👍  ‏‏‎ </span>After 6 referrals...</Header>
                  <Image src="mystery-prize.png" width="200px" />
                  Haley will mail your child a mystery gift box full of Tappity science gear and more to spark their curiosity and imagination!
                 </p>
                <Button color="blue" size="small" disabled={this.state.reward3Earned} type="submit" onClick={this.claimReward3}> Claim reward </Button>
              </Segment>
            </Grid.Column>
            
           
          </Grid>
        );
      }
        }
    }
    
const condition = authUser => !!authUser;
    
    export default withAuthorization(condition)(ReferralTrackerPage);