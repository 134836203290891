import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { SignUpLink } from '../SignUp';
import { PasswordForgetLink } from '../PasswordForget';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import ReactGA from 'react-ga';
import Cookies from 'universal-cookie';
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
} from 'semantic-ui-react';

const halfYearGiftPriceID = "price_1HoCe2ATKVrws6NtAAse1hfJ";

const SignInPage = () => (
  <div>
    <SignInForm />
    <PasswordForgetLink />
    <SignUpLink />
  </div>
);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class SignInFormBase extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    let pathName = window.location.pathname
        ReactGA.pageview(pathName);
  }

  onSubmit = event => {
    const { email, password } = this.state;
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        const cookies = new Cookies();
        const cookiePriceID = cookies.get('price')
        const cookieRefer = cookies.get('refer')

        if (cookiePriceID != null) {
          if (cookiePriceID == halfYearGiftPriceID) {
            this.props.history.push(ROUTES.CHECKOUT);
          } else {
            this.props.history.push(ROUTES.CHECKOUT);
          }
        } 
        else {
          this.props.history.push(ROUTES.REFERRAL_TRACKER);
        }
        
      })
      .catch(error => {
        this.setState({ error });
      });
    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;
    const isInvalid = password === '' || email === '';
    return (
      <Grid centered>
        <Grid.Column mobile={16} tablet={12} computer={8}>
          <Segment padded>
            <Header as="h1" textAlign="center">Sign in</Header>
            <Form size="large" onSubmit={this.onSubmit}>
              <Form.Input
                fluid
                label = "Email address"
                name="email"
                icon="user"
                iconPosition="left"
                value={email}
                onChange={this.onChange}
                type="text"
                placeholder="Email Address"
              />
              <Form.Input
                fluid
                label = "Password"
                icon="lock"
                iconPosition="left"
                name="password"
                value={password}
                onChange={this.onChange}
                type="password"
                placeholder="Password"
              />
              <Button disabled={isInvalid} color="blue" fluid size="large"  type="submit">
                Sign In
              </Button>
              {error && <p>{error.message}</p>}
            </Form>
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }
}

const SignInLink = () => (
  <Grid centered columns={2}>
    <Grid.Column>
      <Segment padded>
      <Header as="h4" textAlign="left">Already have an account? <Link to={ROUTES.SIGN_IN}>Sign in</Link></Header>
      </Segment>
      
    </Grid.Column>
  </Grid>
);

const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);

export default SignInPage;
export { SignInForm, SignInLink };